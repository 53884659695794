import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { Formik, Field, Form, ErrorMessage } from "formik";
import HeroIcon from "../../../assets/hero-image.png";
import LogoIcon from "../../../assets/logo-white.png";
import ToltioIcon from "../../../assets/toltio-1.png";
import TooltipIcon from "../../../assets/tooltip.png";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import PasswordStrengthBar from "react-password-strength-bar";
import { getRetryModal } from "../../../components/RetryModal/RetryContext";
import { useDispatch } from "react-redux";

const ChangePasswordMain = (props) => {
  const dispatch = useDispatch();
  const specificText = useSelector((state) => state.text);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get("token");
  const [index, setIndex] = useState(0);
  const [load, setLoad] = useState(false);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const initialValues = {
    fullName: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])/, // Alphanumeric validation
        "Password must be alphanumeric"
      )
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    if (values.password) {
      const retryableRequest = async () => {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        setLoad(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API}auth/change-password`,
          {
            token: token,
            password: values.password,
          },
          config
        );
        if (response.data.status === "success") {
          localStorage.clear();
          sessionStorage.clear();

          // dispatch(clearTaskListAction());
          // dispatch(clearProjectListAction());
          // dispatch(clearActiveProjectListAction());
          // dispatch(clearOngoingTasksListAction());
          // dispatch(clearTimelineStats());
          // dispatch(emptyTaskTypesListAction());
          // dispatch(emptyProjectFormListingAction());
          // dispatch(emptyProjectListingStatusAction());
          // dispatch(emptyProjectListingAllStatusAction());
          // dispatch(emptyProjectTypeListingAction());
          // dispatch(emptyProjectUserListingAction());
          // dispatch(emptyTaskPrioritiesListAction());
          // dispatch(emptyTeamsListAction());
          // dispatch(clearCachedTasks());
          // dispatch(clearProjectCache());
          // dispatch(clearCompletedTask());
          // dispatch(clearSubscriptionData());
          // dispatch(projectCompletedClearState());
          // await clearAllCache().unwrap();
          // await clearAllCompletedTaskCache().unwrap();
          navigate("/login");
          toast.success("Password updated successfully");
        }
      };
      try {
        await retryableRequest();
      } catch (error) {
        toast.error(error.response.data.message);
        const showRetryModal = getRetryModal();
        showRetryModal(retryableRequest);
      } finally {
        setLoad(false);
      }
    }
  };
  return (
    <section className="signup-main-wraper" data-theme={props.isDark}>
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-6 choose-industry-columns bg-column">
            <div className="signup-left-section">
              <div className="logo-top">
                {" "}
                <a href="#">
                  {" "}
                  <img src={LogoIcon} alt="logo" />{" "}
                </a>{" "}
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center sec-left-img-container">
                <div className="image-slider-main-wrap">
                  <div className="image-slider-wrap">
                    {" "}
                    <img src={HeroIcon} alt="hero-icon" />{" "}
                  </div>
                  <div className="signup-slider-wrap">
                    <Carousel
                      activeIndex={index}
                      controls={false}
                      onSelect={handleSelect}
                      indicators={false}
                    >
                      <Carousel.Item>
                        <div className="slider-signup-text-wrap">
                          <h2>Success to your business</h2>
                          <p>All of your operations on just a click away.</p>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 choose-industry-columns img-column">
            <div className="right-section-wrap h-100">
              <div className="form-section-wrap">
                <h3> Forgot Password </h3>
                <p>
                  {" "}
                  Reset Password - Update your password by entering password
                  information.{" "}
                </p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched }) => (
                    <Form>
                      <div className="form-group">
                        <label>Password</label>
                        <Field
                          type="password"
                          name="password"
                          className={`form-control ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Enter your password"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                        {values.password && (
                          <PasswordStrengthBar
                            className="pass-strength"
                            scoreWords={[
                              "Weak",
                              "Weak",
                              "Medium",
                              "Strong",
                              "Strong",
                            ]}
                            barColors={[
                              "#ddd",
                              "#ef4836",
                              "#f6b44d",
                              "#25c281",
                              "#25c281",
                            ]}
                            password={values.password}
                            shortScoreWord={false}
                            minLength={false}
                          />
                        )}
                      </div>
                      <div className="form-group form-confirm-pass-grp">
                        <label>Confirm Password</label>
                        <Field
                          type="password"
                          name="confirmPassword"
                          className={`form-control ${
                            touched.confirmPassword && errors.confirmPassword
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Enter your Confirm Password"
                        />
                        {values.password &&
                          values.password === values.confirmPassword && (
                            <i className="text-success fas fa-check check-confirm-pass"></i>
                          )}
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="btn-group-signup">
                        <button type="submit">
                          {" "}
                          {load ? (
                            <Spinner animation="border" />
                          ) : (
                            "Continue"
                          )}{" "}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePasswordMain;

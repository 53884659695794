import axios from "axios";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_USER_FAIL,
  LOGOUT_USER_SUCCESS,
  CLEAR_ERRORS,
  LOAD_AUTH_ONBOARDING_CONTENT_REQUEST,
  LOAD_AUTH_ONBOARDING_CONTENT_SUCCESS,
  LOAD_AUTH_ONBOARDING_CONTENT_FAIL,
} from "../constants/userConstants";
import API from "../services/ClientApi";
import toast, { Toaster } from "react-hot-toast";
import {
  COMPANY_LISTING_SUCCESS,
  COMPANY_NAME_REQUEST,
} from "../constants/companyOverviewConstatnt";

// Login action
export const loginUser = (email, password, company_id) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}auth/login`,
      { email, password, company_id },
      config
    );
    console.log("after login data", data);
    if (data.status === "success") {
      localStorage.setItem("access_token", data?.access_token);
      localStorage.setItem("company", data?.data?.companySlug);
      sessionStorage.removeItem("user");
      dispatch({ type: LOGIN_SUCCESS, payload: data });
      return data;
    }
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: error,
      meta: {
        // retryAction: loginUser(email, password, company_id), // Retry action
      },
    });
    return error;
  }
};

export const loginUserThirdParty =
  (email, login_type, login_token, company_id) => async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}auth/login_3rdparty`,
        { email, login_type, login_token, company_id },
        config
      );
      if (data.status === "success") {
        localStorage.setItem("access_token", data?.access_token);
        if (data?.data?.companySlug) {
          localStorage.setItem("company", data?.data?.companySlug);
        }
        sessionStorage.removeItem("user");
        dispatch({ type: LOGIN_SUCCESS, payload: data });
        return data;
      }
    } catch (error) {
      dispatch({
        type: LOGIN_FAIL,
        payload: error,
        meta: {
          // retryAction: loginUserThirdParty(
          //   email,
          //   login_type,
          //   login_token,
          //   company_id
          // ), // Retry action
        },
      });
      return error;
    }
  };

// Register action
export const register = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json", // Since we now submiting images as well
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}auth/register`,
      userData,
      config
    );
    if (data.status === "success") {
      localStorage.setItem("isRegistering", true);
      localStorage.setItem("access_token", data?.access_token);
      // toast.success("Registration successful");
      sessionStorage.removeItem("user");
      return data;
    }
    dispatch({ type: REGISTER_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error,
      meta: {
        // retryAction: register(userData), // Retry action
      },
    });
    return error;
  }
};
// Register action
export const registerThirdParty = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json", // Since we now submiting images as well
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}auth/register_3rdparty`,
      userData,
      config
    );
    if (data.status === "success") {
      localStorage.setItem("isRegistering", true);
      localStorage.setItem("access_token", data?.access_token);
      // toast.success("Registration successful");
      sessionStorage.removeItem("user");
      return data;
    }
    dispatch({ type: REGISTER_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error,
      meta: {
        // retryAction: registerThirdParty(userData), // Retry action
      },
    });
    return error;
  }
};

// User profile PAGE
// User profile PAGE
export const userProfilePage = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });
    const { data } = await API.get("users/basic-info");

    dispatch({
      type: LOAD_USER_SUCCESS,
      payload: data?.data,
    });

    return data;
  } catch (error) {
    dispatch({
      type: LOAD_USER_FAIL,
      payload: error,
      meta: {
        retryAction: userProfilePage(), // Retry action
      },
    });
  }
};

// Logout user action
export const logoutUser = () => async (dispatch) => {
  try {
    await axios.get("/api/v1/logout");
    dispatch({ type: LOGOUT_USER_SUCCESS });
  } catch (error) {
    dispatch({
      type: LOGOUT_USER_FAIL,
      payload: error.response.data.message,
      meta: {
        // retryAction: logoutUser(), // Retry action
      },
    });
  }
};

// get on boarding screen content
export const authOnBoardingScreenContentAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_AUTH_ONBOARDING_CONTENT_REQUEST });
    const { data } = await API.get("auth/onboarding/content");

    dispatch({
      type: LOAD_AUTH_ONBOARDING_CONTENT_SUCCESS,
      payload: data?.data,
    });

    return data;
  } catch (error) {
    dispatch({
      type: LOAD_AUTH_ONBOARDING_CONTENT_FAIL,
      payload: error,
      meta: {
        // retryAction: authOnBoardingScreenContentAction(), // Retry action
      },
    });
  }
};

//Clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};

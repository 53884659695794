import { Row, Col, Card } from "react-bootstrap";
import PaymentForm from "./PaymentForm";
import MembershipDetails from "./MembershipDetails";
import { useEffect, useState } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";
import API from "../../../services/ClientApi";

const PaymentPage = () => {
  const [load, setLoad] = useState(false);
  const [packageData, setPackageData] = useState([]);
  const [membershipType, setMembershipType] = useState("annually");
  const [userCount, setUserCount] = useState(2);
  const [totalAmount, setTotalAmount] = useState(0);

  // Get package data
  useEffect(() => {
    const getPackages = async () => {
      setLoad(true);
      try {
        const response = await API.get("auth/packages");
        setPackageData(response?.data?.data[0]);
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    };

    getPackages();
  }, []);

  return (
    <Card className="w-100 payment_card">
      <Card.Body className="p-4 p-md-5">
        <Row>
          <Col lg={7} className="payment_left_col">
            <h1 className="payment_title">Upgrade to a Pro Membership</h1>
            <p className="payment_subtitle">
              Get all access and an extra 20% off when you subscribe annually
            </p>
            <PaymentForm
              packageData={packageData}
              membershipType={membershipType}
              userCount={userCount}
              totalAmount={totalAmount}
            />
          </Col>
          <Col lg={5} className="payment_right_col">
            {load ? (
              <div className="h-100 d-flex flex-grow-1 flex-column justify-content-between">
                <ShimmerThumbnail
                  height={150}
                  // width={500}
                  className="m-0"
                  rounded
                />
                <ShimmerThumbnail
                  height={150}
                  // width={500}
                  className="m-0"
                  rounded
                />
                <ShimmerThumbnail
                  height={150}
                  // width={500}
                  className="m-0"
                  rounded
                />
              </div>
            ) : (
              <MembershipDetails
                data={packageData}
                membershipType={membershipType}
                setMembershipType={setMembershipType}
                userCount={userCount}
                setUserCount={setUserCount}
                totalAmount={totalAmount}
                setTotalAmount={setTotalAmount}
              />
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default PaymentPage;

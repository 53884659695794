import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import { Modal, Spinner } from "react-bootstrap";

const ChooseCompanyModal = (props) => {
  return (
    <Modal
      show={props.showCompanyModal}
      // onHide={props.handleCloseCompanyModal}
      className="main-update-modal choose-company-modal-main"
      backdrop="static" // Prevents closing on outside click
      keyboard={false} // Prevents closing on pressing the Escape key
      data-theme={props.isDark}
      centered
    >
      <Modal.Header className="update-modal-header choose-company-modal position-relative">
        <h6>
          Choose <span style={{ fontWeight: 300 }}>a company</span>
        </h6>
        <button
          className="custom-modal-close-btn position-absolute top-0 end-0"
          onClick={props.handleCloseCompanyModal}
        >
          <Icon icon="maki:cross" />
        </button>
      </Modal.Header>
      <Modal.Body className="update-modal-body company-modal-body">
        <p className="role-page-description mb-2">
          Multiple accounts associated, please choose one to proceed with
        </p>
        <div className="row company-modal-row">
          {props?.userCompanies?.length > 0 ? (
            props?.userCompanies?.map((res, id) => (
              <div className="col-md-4 modules-opt-wrap">
                <div
                  className={`d-flex flex-column company-select ${
                    props?.selectedCompany === res?.companyId
                      ? "company-select-modal"
                      : ""
                  }`}
                  key={id}
                  onClick={() => {
                    props?.handleChange(res?.companyId);
                  }}
                >
                  <p>{res.companyName}</p>
                  <p>{res.companySlug}</p>
                </div>
              </div>
            ))
          ) : (
            <option value="" disabled>
              No companies available
            </option>
          )}
        </div>
        <div className="mt-3 w-100 d-flex justify-content-end align-items-center company-btn-wrapper">
          <button onClick={props.handleSubmit}>
            {props.rload ? (
              <Spinner className="spinner-role" border="animation" />
            ) : (
              "Select company"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChooseCompanyModal;

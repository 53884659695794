import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { usePostAddCompanyWorkingHoursMutation } from "../../../slices/user/userSlice";

function useAuthSchedule() {
  const navigate = useNavigate();
  const [startTimeValue, setStartTimeValue] = useState(null);
  const [endTimeValue, setEndTimeValue] = useState(null);

  const [
    postAddCompanyWorkingHours,
    { isLoading: isAddCompanyWorkingHoursLoading },
  ] = usePostAddCompanyWorkingHoursMutation();

  // List of available time zones
  const timezonesList = Intl.supportedValuesOf("timeZone");

  const initialValues = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Default to user's time zone
    startTime: "",
    endTime: "",
    days: [], // Default as empty array
  };

  const daysList = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const validationSchema = Yup.object({
    timezone: Yup.string().required("Timezone is required"),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
    days: Yup.array()
      .min(1, "At least one day must be selected")
      .required("Select at least one day"),
  });

  const handleOnSubmit = async (values) => {
    // Ensure start time is before end time
    try {
      // Create payload
      const payload = {
        shiftTitle: "Regular Shift",
        dayOfWeek: values.days.join(","), // Assuming days need to be a comma-separated string
        startTime: values.startTime,
        endTime: values.endTime,
        timeZone: values.timezone,
      };
      const response = await postAddCompanyWorkingHours(payload).unwrap();
      if (response?.status === "success") {
        toast.success(response?.message);
        navigate(`/${localStorage.getItem("company")}/invite-team`);
      } else {
        toast.error(response?.message);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      // handleCloseModal();
    }
  };

  return {
    validationSchema,
    startTimeValue,
    setStartTimeValue,
    endTimeValue,
    setEndTimeValue,
    initialValues,
    handleOnSubmit,
    daysList,
    timezonesList, // Pass timezones list to the component
    isAddCompanyWorkingHoursLoading,
  };
}

export default useAuthSchedule;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleIcon from "../../../assets/google.svg";
import LogoIcon from "../../../assets/logo-white.png";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import API from "../../../services/ClientApi";
import toast from "react-hot-toast";

const SignupMain = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [index, setIndex] = useState(0);
  const [load, setLoad] = useState(false);
  const [gload, setGLoad] = useState(false);
  const initialValues = {
    email: props.user.email_address,
  };
  const [pageContent, setPageContent] = useState({});
  const { onBoardingContent } = useSelector(
    (state) => state.authOnBoardingScreenContent
  );

  useEffect(() => {
    if (onBoardingContent) {
      onBoardingContent?.map((item, index) => {
        if (item?.url === "register") {
          setPageContent(item);
        }
      });
    }
  }, [onBoardingContent]);

  // Search for s param to enable payment in flow
  useEffect(() => {
    const sValue = searchParams.get("s");
    if (sValue) {
      props.setUser({
        ...props.user,
        enable_payment: true,
      });
      sessionStorage.setItem(
        "user",
        JSON.stringify({
          ...props.user,
          enable_payment: true,
        })
      );
    }
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    // You can perform any additional submission logic here
    // If there are no validation errors
    const validate = await validateEmail(values.email);
    if (!validate) {
      setErrors({
        email: "Your email is already registered with a trial period.",
      }); // Set error in Formik
    } else {
      if (values.email) {
        // Navigate to the desired location
        // Get the current timezone
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        try {
          props.setUser({
            ...props.user,
            email_address: values.email,
            timeZone: timeZone, // Add timezone
          });
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              ...props.user,
              email_address: values.email,
              timeZone: timeZone, // Add timezone
            })
          );
          // await axios.post("/test", { email: values.email });
          navigate("/create-account");
        } catch (error) {}
      }
    }
    // Set submitting to false to allow the form to be submitted again
    setSubmitting(false);
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // Google useGoogleLogin
  const login = useGoogleLogin({
    scope: "openid profile email",
    onSuccess: (codeResponse) => {
      setGLoad(true);
      if (codeResponse) {
        axios
          .get(
            `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${codeResponse.access_token}`,
            {
              headers: {
                Authorization: `Bearer ${codeResponse.access_token}`,
                Accept: "application/json",
              },
            }
          )
          .then(async (res) => {
            const validate = await validateEmail(res?.data?.email);
            if (!validate) {
              toast.error(
                "Your email is already registered with a trial period."
              ); // Set error in Formik
            } else {
              const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              props.setUser({
                ...props.user,
                email_address: res?.data?.email,
                login_type: "google",
                login_token: res?.data?.sub,
                timeZone: timeZone, // Add timezone
              });
              sessionStorage.setItem(
                "user",
                JSON.stringify({
                  ...props.user,
                  email_address: res?.data?.email,
                  login_type: "google",
                  login_token: res?.data?.sub,
                  timeZone: timeZone, // Add timezone
                })
              );

              // await axios.post("/test", { email: values.email });
              navigate("/create-account");
            }
            setGLoad(false);
          })
          .catch((err) => {
            return err;
          });
      }
    },
    onError: (error) => console.log(error),
  });

  // useEffect(() => {
  //   if (!props.user.package_id) {
  //     navigate("/packages");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // Validate user email. If he has a account with trial still exist, do not allow user to register. Any one of his subscription being purchase will invalidate this condition.
  const validateEmail = async (email) => {
    try {
      setLoad(true);
      const response = await API.post("auth/validate-signup-email", {
        emailAddress: email,
      });
      if (!response) {
        return false;
      } else {
        return true; // Email validation passed ✅
      }
    } catch (error) {
      return false; // Validation failed ❌
    } finally {
      setLoad(false);
    }
  };

  return (
    <section className="signup-main-wraper">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-6 choose-industry-columns bg-column">
            <div className="signup-left-section">
              <div className="logo-top">
                {" "}
                <a href="#">
                  {" "}
                  <img src={LogoIcon} alt="logo" />{" "}
                </a>{" "}
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center sec-left-img-container">
                <div className="image-slider-main-wrap">
                  <div className="image-slider-wrap">
                    {" "}
                    {pageContent?.imageUrl && (
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          pageContent?.imageUrl
                        }
                        alt="hero-icon"
                      />
                    )}{" "}
                  </div>
                  <div className="signup-slider-wrap">
                    <Carousel
                      activeIndex={index}
                      controls={false}
                      onSelect={handleSelect}
                      indicators={false}
                    >
                      <Carousel.Item>
                        <div className="slider-signup-text-wrap">
                          <h2>{pageContent?.short_description}</h2>
                          <p>{pageContent?.long_description}</p>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 choose-industry-columns img-column">
            <div className="right-section-wrap h-100">
              <div className="sign-in-btn-text txt-desktop">
                {" "}
                <span> Already have an account? </span>{" "}
                <a
                  className="signup-btn"
                  onClick={() => {
                    props.setUser({
                      full_name: "",
                      email_address: "",
                      password: "",
                      passwordConfirm: "",
                      company_name: "",
                      industry_id: 0,
                      package_id: "",
                      modules_category_ids: [],
                      login_type: "",
                      login_token: "",
                    });
                    sessionStorage.removeItem("user");
                    navigate("/login");
                  }}
                >
                  {" "}
                  Sign in{" "}
                </a>
              </div>

              <div className="form-section-wrap">
                <h3> {pageContent?.field_short_description} </h3>
                <p> {pageContent?.field_long_description}</p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched }) => (
                    <Form>
                      <div className="form-group">
                        <label>Email Address*</label>
                        <Field
                          type="email"
                          name="email"
                          className={`form-control ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                          placeholder="info@youremail.com"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="control-group terms-group">
                        <label className="control control--checkbox control-signup">
                          By signing up, you agree to the{" "}
                          <a
                            className="signup-anchors"
                            onClick={() => window.open("/terms-and-conditions")}
                          >
                            Terms of Service
                          </a>{" "}
                          and{" "}
                          <a
                            className="signup-anchors"
                            onClick={() => window.open("/privacy-policy")}
                          >
                            Privacy Policy
                          </a>
                        </label>
                      </div>

                      <div className="btn-group-signup">
                        <button type="submit" disabled={gload || load}>
                          {gload || load ? (
                            <Spinner animation="border" />
                          ) : (
                            "Sign up Your Account"
                          )}
                        </button>
                      </div>

                      <div className="or-text-signup">
                        <span>OR</span>
                      </div>
                    </Form>
                  )}
                </Formik>
                <div className="google-btn-wrap">
                  <button onClick={login}>
                    {" "}
                    <img src={GoogleIcon} alt="google" /> Continue with Google{" "}
                  </button>
                </div>
                <div className="sign-in-btn-text txt-mobile">
                  {" "}
                  <span> Already have an account? </span>{" "}
                  <a className="signup-btn" onClick={() => navigate("/login")}>
                    {" "}
                    Sign in{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignupMain;

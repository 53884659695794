/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import HeroIcon from "../../../assets/hero-image.png";
import LogoIcon from "../../../assets/logo-white.png";
import Carousel from "react-bootstrap/Carousel";
import toast, { Toaster } from "react-hot-toast";
import { Spinner, Form } from "react-bootstrap";
import API from "../../../services/ClientApi";
import axios from "axios";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const ForgotPasswordMain = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [load, setLoad] = useState(false);
  const [userCompanies, setUserCompanies] = useState([]);
  const [index, setIndex] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState("");
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        setSubmitting(true);
        const response = await API.post("auth/forget-password", {
          email: values.email,
          company_id: selectedCompany,
        });
        if (response.data.status === "success") {
          toast.success("To change password, please check your email");
        } else {
          setFieldError("email", response.response.data.message);
        }
      } catch (error) {
        // Handle error from "auth/user-companies-by-email" API call
        setFieldError("email", "Invalid email. User not found.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleChange = (e) => {
    e.preventDefault();
    setSelectedCompany(e.target.value);
  };

  return (
    <section className="signup-main-wraper">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-6 choose-industry-columns bg-column">
            <div className="signup-left-section">
              <div className="logo-top logo-margin">
                {" "}
                <a href="#">
                  {" "}
                  <img src={LogoIcon} alt="logo" />{" "}
                </a>{" "}
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center sec-left-img-container">
                <div className="image-slider-main-wrap">
                  <div className="image-slider-wrap">
                    {" "}
                    <img src={HeroIcon} alt="hero-icon" />{" "}
                  </div>
                  <div className="signup-slider-wrap">
                    <Carousel
                      activeIndex={index}
                      controls={false}
                      onSelect={handleSelect}
                      indicators={false}
                    >
                      <Carousel.Item>
                        <div className="slider-signup-text-wrap">
                          <h2>Your online business partners.</h2>
                          <p>All your operations on just a click away.</p>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 choose-industry-columns img-column">
            <div className="right-section-wrap h-100">
              <div className="sign-in-btn-text txt-desktop">
                {" "}
                <span> Login to your account? </span>{" "}
                <a className="signup-btn" onClick={() => navigate("/login")}>
                  {" "}
                  Sign in{" "}
                </a>
              </div>
              <div className="form-section-wrap login-section-wrap forget-password-wrap">
                <h3> Forgot Password </h3>
                <p> Change your password </p>
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <label> Email Address </label>
                    <input
                      type="email"
                      className={`form-control ${
                        formik.touched.email && formik.errors.email
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Info@youremail.com"
                      name="email"
                      onBlur={formik.handleBlur}
                      onChange={async (e) => {
                        formik.setFieldError("email", "");
                        formik.handleChange(e);
                        try {
                          setLoad(true);
                          const config = {
                            headers: {
                              "Content-type": "application/json",
                              Authorization: `Bearer ${
                                localStorage.getItem("access_token") || ""
                              }`,
                            },
                          };
                          const resp = await axios.post(
                            `${process.env.REACT_APP_BACKEND_API}auth/user-companies-by-email`,
                            {
                              email_address: e.target.value,
                            },
                            config
                          );
                          if (resp?.data?.data) {
                            setUserCompanies(Object.values(resp?.data?.data));
                            const data = Object.values(resp?.data?.data);
                            if (data.length > 0) {
                              setSelectedCompany(data[0]?.companyId);
                            }
                          }
                        } catch (error) {
                          // Handle error from "auth/user-companies-by-email" API call
                        } finally {
                          setLoad(false);
                        }
                      }}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="invalid-feedback right-error">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>

                  {userCompanies?.length > 1 && (
                    <div className="form-group">
                      <label> Company </label>
                      <Form.Select
                        className="form-control company-select"
                        placeholder="Choose a company"
                        value={
                          selectedCompany !== undefined &&
                          selectedCompany !== null
                            ? selectedCompany
                            : ""
                        }
                        disabled={userCompanies.length === 0}
                        onChange={handleChange}
                      >
                        {userCompanies.length > 0 ? (
                          userCompanies.map((res, id) => (
                            <option key={id} value={res.companyId}>
                              {res.companyName}
                            </option>
                          ))
                        ) : (
                          <option value="" disabled>
                            No companies available
                          </option>
                        )}
                      </Form.Select>
                    </div>
                  )}

                  <div className="btn-group-signup">
                    <button
                      className="d-flex align-items-center justify-content-center"
                      type="submit"
                      disabled={load || formik.isSubmitting}
                    >
                      {" "}
                      {formik.isSubmitting ? (
                        <Spinner animation="border" />
                      ) : (
                        "Forgot password"
                      )}{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordMain;

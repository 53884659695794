import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import visaLogo from "../../../assets/visa_logo.svg";
import masterCardLogo from "../../../assets/mastercard_logo.svg";
import payPalLogo from "../../../assets/paypal_logo.svg";
import toast from "react-hot-toast";
import API from "../../../services/ClientApi";
import { useNavigate } from "react-router-dom";
import { userProfilePage } from "../../../actions/userActions";
import { useDispatch } from "react-redux";

const PaymentForm = ({
  packageData,
  membershipType,
  userCount,
  totalAmount,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [cardErrors, setCardErrors] = useState({});
  const [touched, setTouched] = useState({});

  const stripeElementStyle = {
    base: {
      fontSize: "16px",
      color: "#333",
      fontFamily: "Inter, sans-serif",
      "::placeholder": {
        color: "#aab7c4",
      },
      padding: "10px 0",
    },
    invalid: {
      color: "#dc3545",
      iconColor: "#dc3545",
    },
  };

  const handleCardChange = (event, elementType) => {
    setTouched((prev) => ({ ...prev, [elementType]: true }));
    setCardErrors((prev) => ({
      ...prev,
      [elementType]: event.error ? event.error.message : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if Stripe and Elements are loaded
    if (!stripe || !elements) {
      return;
    }

    // Set loading state to true
    setIsProcessing(true);

    // Get Stripe card elements
    const cardElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    // Create a Stripe token
    const { token, error } = await stripe.createToken(cardElement);

    // Check for empty and invalid fields
    const emptyFields = {
      cardNumber: cardElement._empty,
      cardExpiry: cardExpiryElement._empty,
      cardCvc: cardCvcElement._empty,
    };

    const invalidFields = {
      cardNumber: cardElement._invalid,
      cardExpiry: cardExpiryElement._invalid,
      cardCvc: cardCvcElement._invalid,
    };

    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = "Name is required";
    }

    // Validate empty fields
    Object.keys(emptyFields).forEach((field) => {
      if (emptyFields[field]) {
        newErrors[field] = "Field is required";
      }
    });

    // Validate invalid fields
    Object.keys(invalidFields).forEach((field) => {
      if (invalidFields[field]) {
        newErrors[field] = "Field is invalid";
      }
    });

    // If there are errors, set them and stop processing
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsProcessing(false);
      return;
    }

    // If no errors, proceed with payment
    if (Object.keys(newErrors).length <= 0) {
      if (token === undefined) {
        console.log("Stripe Error:", error);
        toast.error(error?.message || "An error occurred", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        setIsProcessing(false);
      } else {
        try {
          setIsProcessing(true);

          // Handle payment processing based on selected users

          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          const res = await API.post(
            `company/process-payment`,
            {
              stripeProductId: packageData?.stripeProductId,
              packageId: packageData?.id,
              totalAmount: totalAmount,
              token: token?.id,
              seatCount: parseInt(userCount),
              interval: membershipType === "monthly" ? "month" : "year",
            },
            config
          );

          if (res.error) {
            setIsProcessing(false);
            toast.error("An error occurred");
          } else {
            setIsProcessing(false);
            dispatch(userProfilePage());
            toast.success("Payment successful");
            navigate(`/${localStorage.getItem("company")}/working-schedule`);
          }
        } catch (error) {
          setIsProcessing(false);
          toast.error("An error occurred");
        }
      }
    }
  };

  return (
    <Form className="payment_form" onSubmit={handleSubmit}>
      <div className="payment_methods_wrapper">
        <p className="billed_to_text">Billed to</p>
        <div className="payment_methods">
          <img src={visaLogo} alt="Visa" className="payment_method_icon" />
          <img
            src={masterCardLogo}
            alt="Mastercard"
            className="payment_method_icon"
          />
          <img src={payPalLogo} alt="PayPal" className="payment_method_icon" />
        </div>
      </div>

      <Form.Group className="mb-3">
        <Form.Label className="payment_form_label">Name on Card*</Form.Label>
        <Form.Control
          type="text"
          placeholder="John Smith"
          className="payment_form_input"
          value={name}
          onChange={(e) => {
            const newName = e.target.value;
            setName(newName);

            // Clear the name error if the user starts typing
            if (newName.trim() && errors.name) {
              setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
            }
          }}
          isInvalid={!!errors.name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.name}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="payment_form_label">Card Number*</Form.Label>
        <div
          className={`stripe_element_container ${
            touched.cardNumber && cardErrors.cardNumber ? "is-invalid" : ""
          }`}
        >
          <CardNumberElement
            options={{ style: stripeElementStyle }}
            onChange={(e) => handleCardChange(e, "cardNumber")}
            className="stripe_element"
          />
        </div>
        {cardErrors.cardNumber && (
          <div className="invalid_feedback text-end">
            {cardErrors.cardNumber}
          </div>
        )}
      </Form.Group>

      <div className="payment_form_row">
        <Form.Group className="mb-3 payment_form_expiry">
          <Form.Label className="payment_form_label">Expiry Date*</Form.Label>
          <div
            className={`stripe_element_container ${
              touched.cardExpiry && cardErrors.cardExpiry ? "is-invalid" : ""
            }`}
          >
            <CardExpiryElement
              options={{ style: stripeElementStyle }}
              onChange={(e) => handleCardChange(e, "cardExpiry")}
              className="stripe_element"
            />
          </div>
          {cardErrors.cardExpiry && (
            <div className="invalid_feedback text-end">
              {cardErrors.cardExpiry}
            </div>
          )}
        </Form.Group>

        <Form.Group className="mb-3 payment_form_cvv">
          <Form.Label className="payment_form_label">CVV*</Form.Label>
          <div
            className={`stripe_element_container ${
              touched.cardCvc && cardErrors.cardCvc ? "is-invalid" : ""
            }`}
          >
            <CardCvcElement
              options={{ style: stripeElementStyle }}
              onChange={(e) => handleCardChange(e, "cardCvc")}
              className="stripe_element"
            />
          </div>
          {cardErrors.cardCvc && (
            <div className="invalid_feedback text-end">
              {cardErrors.cardCvc}
            </div>
          )}
        </Form.Group>
      </div>

      <Button
        variant="primary"
        type="submit"
        className="payment_submit_btn"
        disabled={isProcessing || !stripe}
      >
        {isProcessing ? "Processing..." : "Pay Now!"}
      </Button>
    </Form>
  );
};

export default PaymentForm;

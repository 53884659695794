import React from "react";
import { Modal } from "react-bootstrap";
import DeleteIcon from "../../../assets/infoMain.svg";

const NoSeatsModal = (props) => {
  return (
    <Modal
      show={props.showAddModal}
      onHide={() => props.setShowAddModal(false)}
      centered
      size="md"
      data-theme={props?.isDark}
      backdrop="static"
      keyboard={false}
      className="main-delete-modal"
    >
      {/* <Modal.Header className="border-0" closeButton></Modal.Header> */}

      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        {/* <h5>Verify Email</h5>
          <p>To access the system, please verify your email</p> */}
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="info-icon" />
        </div>
        <h5>Seat limit reached</h5>
        <p>
          You have reached the seat limit. Please upgrade your plan to add more
          seats, and invite more members.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            className="w-100 delete-btn-1"
            onClick={() => props.setShowAddModal(false)}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default NoSeatsModal;

import React, { useMemo } from "react";
import TimePicker from "../../../components/TimePicker";
import { Formik, Form } from "formik";
import useAuthSchedule from "../service/useAuthSchedule";
import Select, { components } from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import { Spinner } from "react-bootstrap";

const TimezoneAuthForm = () => {
  const {
    validationSchema,
    startTimeValue,
    setStartTimeValue,
    endTimeValue,
    setEndTimeValue,
    handleOnSubmit,
    initialValues,
    daysList,
    isAddCompanyWorkingHoursLoading,
  } = useAuthSchedule();

  // 1. Memoize timezones list
  const timezones = useMemo(() => Intl.supportedValuesOf("timeZone"), []);

  // 2. Memoize options array
  const timezoneOptions = useMemo(
    () => timezones.map((tz) => ({ value: tz, label: tz })),
    [timezones]
  );

  return (
    <div className="timezone-auth-form-container">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await handleOnSubmit(values);
        }}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form>
            <div className="set-hour-container">
              {/* Time Zone Selection */}
              <div className="set-hour-title-section">
                <label htmlFor="timezone" className="set-hour-label">
                  Time Zone
                </label>
                <Select
                  id="timezone"
                  name="timezone"
                  classNamePrefix="select"
                  menuShouldBlockScroll={true} // Better scroll behavior
                  menuShouldScrollIntoView={true} // Better navigation
                  className={`timezone-select-auth ${
                    touched.timezone && errors.timezone ? "is-invalid" : ""
                  }`}
                  value={{
                    value: values.timezone,
                    label: values.timezone,
                  }} // Match selected value
                  onChange={(selectedOption) => {
                    setFieldValue("timezone", selectedOption.value);
                  }}
                  options={timezoneOptions} // Convert to label-value pairs
                  isSearchable
                  placeholder="Select Timezone"
                  components={{ Control: CustomControl }}
                  menuPosition="auto"
                />
                {touched.timezone && errors.timezone && (
                  <div className="invalid-feedback">{errors.timezone}</div>
                )}
              </div>

              {/* Select Days */}
              <div className="set-hour-days-section">
                <label className="set-hour-label">Working Days</label>
                <div className="set-hour-days-container">
                  {daysList.map((day, index) => (
                    <button
                      key={index}
                      type="button"
                      onClick={() => {
                        const newDays = Array.isArray(values.days)
                          ? values.days.includes(day)
                            ? values.days.filter((d) => d !== day)
                            : [...values.days, day]
                          : [day]; // Ensure it's an array
                        setFieldValue("days", newDays);
                      }}
                      className={`set-hour-day-button ${
                        values.days && values.days.includes(day)
                          ? "set-hour-day-selected"
                          : ""
                      }`}
                    >
                      {day}
                    </button>
                  ))}
                </div>
                {touched.days && errors.days && (
                  <div className="invalid-feedback-days">{errors.days}</div>
                )}
              </div>

              {/* Timing Selection */}
              <div className="set-hour-timing-section">
                <label className="set-hour-label">Time Schedule</label>
                <div className="set-hour-timing-container">
                  {/* Start Time */}
                  <div className="set-hour-time">
                    <label htmlFor="startTime" className="set-hour-time-label">
                      Start Time
                    </label>
                    <TimePicker
                      time={startTimeValue}
                      setTime={(time) => {
                        setStartTimeValue(time);
                        setFieldValue("startTime", time);
                      }}
                      placeholder="Start time"
                      timeDifference={15}
                      isInvalid={touched.startTime && errors.startTime}
                    />
                    {touched.startTime && errors.startTime && (
                      <div className="invalid-field">{errors.startTime}</div>
                    )}
                  </div>
                  {/* End Time */}
                  <div className="set-hour-time">
                    <label htmlFor="endTime" className="set-hour-time-label">
                      End Time
                    </label>
                    <TimePicker
                      time={endTimeValue}
                      setTime={(time) => {
                        setEndTimeValue(time);
                        setFieldValue("endTime", time);
                      }}
                      placeholder="End time"
                      timeDifference={15}
                      isInvalid={touched.endTime && errors.endTime}
                    />
                    {touched.endTime && errors.endTime && (
                      <div className="invalid-field">{errors.endTime}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="choose-continue-btn choose-continue-btn-margin">
              <div className="next-button-wraper">
                <button type="submit">
                  {" "}
                  {isAddCompanyWorkingHoursLoading ? (
                    <Spinner size="1rem" />
                  ) : (
                    "Continue"
                  )}{" "}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const CustomControl = ({ children, ...props }) => (
  <components.Control {...props}>
    {children}
    <div className="select-icon me-1">
      <IoIosArrowDown />
    </div>
  </components.Control>
);

export default TimezoneAuthForm;

import logo from "../../../assets/logoOne.png"

const Logo = () => {
  return (
    <div className="logo_wrapper">
      <img src={logo} alt="iffra logo" className="logo_image" />
    </div>
  )
}

export default Logo


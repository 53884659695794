import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./index.scss";
import "./pages/styles/leadsNew.css"
import store from "./store.js";
import { Provider } from "react-redux";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { RetryProvider } from "./components/RetryModal/RetryContext.jsx";

const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RetryProvider>
      <GoogleOAuthProvider clientId={googleClientID}>
        <App />
      </GoogleOAuthProvider>
    </RetryProvider>
  </Provider>
);

// Service worker file
// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", function () {
//     navigator.serviceWorker
//       .register("/firebase-messaging-sw.js")

//       .then(
//         function (registration) {
//           // Registration was successful
//           console.log(
//             "Service Worker registered with scope:",
//             registration.scope
//           );
//         },
//         function (err) {
//           // registration failed :(
//           console.log("ServiceWorker registration failed: ", err);
//         }
//       );
//   });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { calculateDiscountedPrice } from "../../../helper/getDiscountedPrice";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { IoIosArrowDown } from "react-icons/io";

const MembershipDetails = ({
  data,
  membershipType,
  setMembershipType,
  userCount,
  setUserCount,
  totalAmount,
  setTotalAmount,
}) => {
  // Total payment amount
  useEffect(() => {
    if (membershipType === "monthly") {
      // Round to 2 decimal places
      setTotalAmount(parseFloat((data?.packagePrice * userCount).toFixed(2)));
    } else {
      // Round to 2 decimal places
      setTotalAmount(
        parseFloat(
          (
            calculateDiscountedPrice(
              data?.packagePrice,
              data?.yearlyDiscountedPercentage
            ) *
            userCount *
            12
          ).toFixed(2)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipType, userCount, data]);

  // Generate options for the dropdown
  const options = Array.from({ length: 98 }, (_, i) => i + 2).map((num) => ({
    value: num,
    label: `${num < 10 ? `0${num}` : num} ${num === 1 ? "user" : "users"}`,
  }));

  // Handle selection change
  const handleChange = (selectedOption) => {
    // Extract the value (number) from the selected option
    setUserCount(selectedOption.value);
  };

  // Convert userCount (number) to the format React Select expects
  const getDefaultValue = () => {
    return {
      value: userCount,
      label: `${userCount < 10 ? `0${userCount}` : userCount} ${
        userCount === 1 ? "user" : "users"
      }`,
    };
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #ccc",
      boxShadow: "none",
      width: "180px !important", // Set width
      height: "45px !important", // Set height
      borderRadius: "6px", // Set border radius
      "&:hover": {
        borderColor: "#888",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
      backgroundColor: state.isSelected
        ? "#007bff" // Selected option background
        : state.isFocused
        ? "#f0f0f0" // Focused option background
        : "white", // Default option background
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
    }),
  };

  const CustomSeatEditorControl = ({ children, menuIsOpen, ...props }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ color: "blue !important" }}>{children}</div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  return (
    <div className="membership_details_wrapper">
      <h2 className="membership_type_title">Membership Type</h2>

      <div className="membership_options">
        <div
          className={`membership_option ${
            membershipType === "monthly" ? "selected" : ""
          }`}
        >
          <Form.Check
            type="radio"
            id="monthly"
            name="membershipType"
            checked={membershipType === "monthly"}
            onChange={() => setMembershipType("monthly")}
            label={
              <div className="membership_option_content">
                <div className="d-flex flex-column">
                  <span className="membership_option_title">Pay Monthly</span>
                  <span className="membership_option_price">
                    $
                    {parseFloat(
                      (data?.packagePrice * userCount).toFixed(2)
                    ).toFixed(2)}{" "}
                    / Month Per Member
                  </span>
                </div>
              </div>
            }
            className="membership_radio"
          />
        </div>

        <div
          className={`membership_option ${
            membershipType === "annually" ? "selected" : ""
          }`}
        >
          <Form.Check
            type="radio"
            id="annually"
            name="membershipType"
            checked={membershipType === "annually"}
            onChange={() => setMembershipType("annually")}
            label={
              <div className="membership_option_content d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <span className="membership_option_title">Pay Annually</span>
                  <span className="membership_option_price">
                    $
                    {parseFloat(
                      (
                        calculateDiscountedPrice(
                          data?.packagePrice,
                          data?.yearlyDiscountedPercentage
                        ) * userCount
                      ).toFixed(2)
                    ).toFixed(2)}{" "}
                    / Month Per Member
                  </span>
                </div>
                <div className="save_badge ">
                  Save {data?.yearlyDiscountedPercentage}%
                </div>
              </div>
            }
            className="membership_radio"
          />
        </div>
      </div>

      <div className="users_selection">
        <div className="user_selection_wrap">
          <h3 className="users_selection_title">Select no. of Users</h3>
          <div className="users_selection_row d-flex align-items-center justify-content-between">
            {/* <Form.Select
              className="users_dropdown"
              value={userCount}
              onChange={(e) => setUserCount(Number.parseInt(e.target.value))}
              data-live-search="true" // Enable search functionality
              data-size="10" // Set the number of visible options
            >
              {Array.from({ length: 98 }, (_, i) => i + 2).map((num) => (
                <option key={num} value={num}>
                  {num < 10 ? `0${num}` : num} {num === 1 ? "user" : "users"}
                </option>
              ))}
            </Form.Select> */}
            <Select
              // className="users_dropdown"
              value={getDefaultValue()} // Convert userCount to the expected format
              onChange={handleChange}
              options={options}
              isSearchable={true} // Enable search functionality
              menuPlacement="auto" // Adjust dropdown placement
              styles={customStyles}
              components={{ Control: CustomSeatEditorControl }}
            />

            <div className="price_calculation ">
              {membershipType === "annually" && (
                <span>
                  12 months x $
                  {parseFloat(
                    (
                      calculateDiscountedPrice(
                        data?.packagePrice,
                        data?.yearlyDiscountedPercentage
                      ) * userCount
                    ).toFixed(2)
                  ).toFixed(2)}{" "}
                  ={" "}
                  <span className="highlight_price">
                    ${totalAmount.toFixed(2)}
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="total_amount_section">
          <div className="total_amount_row">
            <span className="total_amount_label">Total amount</span>
            <span className="total_amount_value">
              ${totalAmount.toFixed(2)}
            </span>
          </div>
          <div className="billing_period">
            Billed {membershipType === "monthly" ? "Monthly" : "Annually"}
          </div>
        </div>
      </div>

      <div className="terms_section">
        <p className="terms_text">
          By continuing{" "}
          <a
            href="https://iffra.com/terms-condition.html"
            target="_blank"
            rel="noopener noreferrer"
            className="terms_link"
          >
            you agree to our terms and conditions
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default MembershipDetails;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import LogoIcon from "../../../assets/logo-white.png";
import Carousel from "react-bootstrap/Carousel";
import PasswordStrengthBar from "react-password-strength-bar";
import { Spinner } from "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.css";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react/dist/iconify.js";

const ChooseAccountMain = (props) => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [invalidCompany, setInvalidCompany] = useState(false);
  const [load] = useState(false);

  const [pageContent, setPageContent] = useState({});
  const { onBoardingContent } = useSelector(
    (state) => state.authOnBoardingScreenContent
  );

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = (e) => {
    e.stopPropagation();
    setShowPassword((prevState) => !prevState);
  };

  const toggleConfirmPasswordVisibility = (e) => {
    e.stopPropagation();
    setShowConfirmPassword((prevState) => !prevState);
  };

  useEffect(() => {
    if (onBoardingContent) {
      // eslint-disable-next-line array-callback-return
      onBoardingContent?.map((item, index) => {
        if (item?.url === "create-account") {
          setPageContent(item);
        }
      });
    }
  }, [onBoardingContent]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const formik = useFormik({
    initialValues: {
      fullName: props.user.full_name,
      company: props.user.company_name,
      password: props.user.password,
      confirmPassword: props.user.passwordConfirm,
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .required("Required")
        .matches(/^[a-zA-Z\s]*$/, "Invalid name"),
      company: Yup.string().required("Required"),
      password: Yup.string()
        .required("Required")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*[0-9])/, // Alphanumeric validation
          "Password must be alphanumeric"
        )
        .min(6, "Password must be at least 6 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      props.setUser({
        ...props.user,
        full_name: values.fullName,
        company_name: values.company,
        password: values.password,
        passwordConfirm: values.confirmPassword,
      });
      sessionStorage.setItem(
        "user",
        JSON.stringify({
          ...props.user,
          full_name: values.fullName,
          company_name: values.company,
          password: values.password,
          passwordConfirm: values.confirmPassword,
        })
      );
      navigate("/choose-industry");
    },
  });

  useEffect(() => {
    if (!props.user.email_address) {
      navigate("/register");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const passwordsMatch =
    formik.values.password &&
    formik.values.password === formik.values.confirmPassword;

  const handleCompanyChange = (e) => {
    formik.handleChange(e); // This updates the formik state
    setInvalidCompany(false); // Reset the invalidCompany state to false
  };

  return (
    <section className="signup-main-wraper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 choose-industry-columns bg-column">
            <div className="signup-left-section">
              <div className="logo-top">
                {" "}
                <a href="">
                  {" "}
                  <img src={LogoIcon} alt="logo" />{" "}
                </a>{" "}
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center sec-left-img-container">
                <div className="image-slider-main-wrap">
                  <div className="image-slider-wrap">
                    {" "}
                    {pageContent?.imageUrl && (
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          pageContent?.imageUrl
                        }
                        alt="hero-icon"
                      />
                    )}{" "}
                  </div>
                  <div className="signup-slider-wrap">
                    <Carousel
                      activeIndex={index}
                      controls={false}
                      onSelect={handleSelect}
                      indicators={false}
                    >
                      <Carousel.Item>
                        <div className="slider-signup-text-wrap">
                          <h2>{pageContent?.short_description}</h2>
                          <p>{pageContent?.long_description}</p>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 choose-industry-columns img-column">
            <div className="create-account-main-wrap">
              <div className="create-account-form-wraper">
                <h3> {pageContent?.field_short_description} </h3>
                <p> {pageContent?.field_long_description}</p>
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <label> Full Name* </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.touched.fullName && formik.errors.fullName
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter Your Full Name"
                      {...formik.getFieldProps("fullName")}
                    />
                    {formik.touched.fullName && formik.errors.fullName && (
                      <div className="invalid-feedback">
                        {formik.errors.fullName}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label> Company* </label>
                    <input
                      type="text"
                      className={`form-control ${
                        (formik.touched.company && formik.errors.company) ||
                        invalidCompany
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter Your Company Name"
                      {...formik.getFieldProps("company")}
                      onChange={handleCompanyChange}
                    />
                    {formik.touched.company && formik.errors.company && (
                      <div className="invalid-feedback">
                        {formik.errors.company}
                      </div>
                    )}
                    {/* {invalidCompany && (
                      <div className="invalid-feedback">
                        Company name already exists
                      </div>
                    )} */}
                  </div>
                  <div className="form-group password-input-wrapper">
                    <label> Password* </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`form-control ${
                        formik.touched.password && formik.errors.password
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter Your Password"
                      {...formik.getFieldProps("password")}
                    />
                    {formik.values.password && (
                      <span
                        className={`password-toggle-icon-reg ${
                          formik.touched.password && formik.errors.password
                            ? "password-toggle-top-reg"
                            : ""
                        }`}
                        onClick={togglePasswordVisibility}
                      >
                        <Icon
                          icon={showPassword ? "el:eye-close" : "el:eye-open"}
                        />
                      </span>
                    )}
                    {formik.touched.password && formik.errors.password && (
                      <div className="invalid-feedback">
                        {formik.errors.password}
                      </div>
                    )}
                    {formik.values.password && (
                      <PasswordStrengthBar
                        className="pass-strength"
                        scoreWords={[
                          "Weak",
                          "Weak",
                          "Medium",
                          "Strong",
                          "Strong",
                        ]}
                        barColors={[
                          "#ddd",
                          "#ef4836",
                          "#f6b44d",
                          "#25c281",
                          "#25c281",
                        ]}
                        password={formik.values.password}
                        shortScoreWord={false}
                        minLength={false}
                      />
                    )}
                  </div>
                  <div className="form-group form-confirm-pass-grp">
                    <label> Confirm Password* </label>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className={`form-control ${
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter Your Confirm Password"
                      {...formik.getFieldProps("confirmPassword")}
                    />
                    {formik.values.confirmPassword && (
                      <span
                        className={`password-confirm-toggle-icon-reg ${
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                            ? "password-confirm-toggle-top-reg"
                            : ""
                        }`}
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        <Icon
                          icon={
                            showConfirmPassword ? "el:eye-close" : "el:eye-open"
                          }
                        />
                      </span>
                    )}
                    {passwordsMatch && (
                      <i className="text-success fas fa-check check-confirm-pass"></i>
                    )}
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword && (
                        <div className="invalid-feedback">
                          {formik.errors.confirmPassword}
                        </div>
                      )}
                  </div>
                  <div className="button-continue-wrap">
                    <button
                      type="submit"
                      className="d-flex align-items-center justify-content-center acc-cont-btn"
                    >
                      {" "}
                      {load ? <Spinner animation="border" /> : "Continue"}{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseAccountMain;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../../../services/ClientApi";
import LogoIcon from "../../../assets/logo-white.png";
import Carousel from "react-bootstrap/Carousel";
import { Spinner } from "react-bootstrap";
import "../styles/module.css";
import { register, registerThirdParty } from "../../../actions/userActions";
import toast, { Toaster } from "react-hot-toast";
import { requestForToken } from "../../../firebase-config";

const ModuleMain = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [load, setLoad] = useState(false);
  const [loadbtn, setLoadBtn] = useState(false);
  const [modules, setModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState(
    props?.user?.modules_category_ids ? props?.user?.modules_category_ids : []
  );
  const [noModuleErr, setNoModuleErr] = useState(false);
  const [pageContent, setPageContent] = useState({});
  const { onBoardingContent } = useSelector(
    (state) => state.authOnBoardingScreenContent
  );

  useEffect(() => {
    if (onBoardingContent) {
      // eslint-disable-next-line array-callback-return
      onBoardingContent?.map((item, index) => {
        if (item?.url === "modules") {
          setPageContent(item);
        }
      });
    }
  }, [onBoardingContent]);

  useEffect(() => {
    const getModules = async () => {
      try {
        setLoad(true);
        const response = await API.get("auth/categories");
        setModules(response?.data?.data?.moduleCategories);
      } catch (error) {
      } finally {
        setLoad(false);
      }
    };

    getModules();
  }, []);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleModuleClick = (moduleId) => {
    setNoModuleErr(false);
    setSelectedModules((prevSelectedModules) => {
      if (prevSelectedModules?.includes(moduleId)) {
        // If the module is already selected, remove it
        return prevSelectedModules.filter((id) => id !== moduleId);
      } else {
        // If the module is not selected, add it
        return [...prevSelectedModules, moduleId];
      }
    });
  };

  const handleSubmit = async () => {
    setLoadBtn(true);
    if (selectedModules.length > 0) {
      try {
        const { login_type, login_token, ...userWithoutSensitiveInfo } =
          props.user;
        const response = await dispatch(
          register({
            ...userWithoutSensitiveInfo,
            modules_category_ids: selectedModules,
          })
        );
        if (response?.status === "error") {
          toast.error(response?.data?.message);
        }
        if (response?.status === "success") {
          const permissions = await API.get("users/permissions-urls");
          localStorage.setItem(
            "permissions",
            JSON.stringify(permissions?.data?.data)
          );

          localStorage.setItem("company", response?.data?.companySlug);
          props?.setCompanySlug(response?.data?.companySlug);
          if (
            permissions?.data?.status === "success" &&
            localStorage.getItem("company") &&
            localStorage.getItem("permissions")
          ) {
            requestForToken();
            navigate(`/${localStorage.getItem("company")}/invite-team`);
            props.setUser({
              full_name: "",
              email_address: "",
              password: "",
              passwordConfirm: "",
              company_name: "",
              industry_id: 0,
              package_id: "",
              modules_category_ids: [],
              login_type: "",
              login_token: "",
            });
          }
        }
      } catch (error) {
      } finally {
        setLoadBtn(false);
      }
    } else {
      setNoModuleErr(true);
      setLoadBtn(false);
    }
  };

  const handleGoogleSubmit = async () => {
    setLoadBtn(true);
    if (selectedModules.length > 0) {
      try {
        const { password, passwordConfirm, ...userWithoutSensitiveInfo } =
          props.user;
        const response = await dispatch(
          registerThirdParty({
            ...userWithoutSensitiveInfo,
            modules_category_ids: selectedModules,
          })
        );
        if (response?.status === "error") {
          toast.error(response?.data?.message);
        }
        if (response?.status === "success") {
          const permissions = await API.get("users/permissions-urls");
          localStorage.setItem(
            "permissions",
            JSON.stringify(permissions?.data?.data)
          );

          localStorage.setItem("company", response?.data?.companySlug);
          props?.setCompanySlug(response?.data?.companySlug);

          if (
            permissions?.data?.status === "success" &&
            localStorage.getItem("company") &&
            localStorage.getItem("permissions")
          ) {
            requestForToken();
            navigate(`/${response?.data?.companySlug}/invite-team`);
            props.setUser({
              full_name: "",
              email_address: "",
              password: "",
              passwordConfirm: "",
              company_name: "",
              industry_id: 0,
              package_id: "",
              modules_category_ids: [],
              login_type: "",
              login_token: "",
            });
          }
        }
      } catch (error) {
      } finally {
        setLoadBtn(false);
      }
    } else {
      setNoModuleErr(true);
      setLoadBtn(false);
    }
  };

  useEffect(() => {
    if (!props.user.industry_id) {
      navigate("/choose-industry");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="signup-main-wraper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 choose-industry-columns bg-column">
            <div className="signup-left-section">
              <div className="logo-top">
                {" "}
                <a href="#">
                  {" "}
                  <img src={LogoIcon} alt="logo" />{" "}
                </a>{" "}
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center sec-left-img-container">
                <div className="image-slider-main-wrap">
                  <div className="image-slider-wrap">
                    {" "}
                    {pageContent?.imageUrl && (
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          pageContent?.imageUrl
                        }
                        alt="hero-icon"
                      />
                    )}{" "}
                  </div>
                  <div className="signup-slider-wrap">
                    <Carousel
                      activeIndex={index}
                      controls={false}
                      onSelect={handleSelect}
                      indicators={false}
                    >
                      <Carousel.Item>
                        <div className="slider-signup-text-wrap">
                          <h2>{pageContent?.short_description}</h2>
                          <p>{pageContent?.long_description}</p>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 choose-industry-columns img-column">
            <div className="choose-industry-wraper">
              {load ? (
                <div className="w-100 d-flex justify-content-center global-spinner">
                  <Spinner animation="border" />
                </div>
              ) : (
                <div className="funkyradio">
                  <h3> {pageContent?.field_short_description} </h3>
                  <p> {pageContent?.field_long_description}</p>
                  <div className="row">
                    {modules.map((res, id) => (
                      <div className="col-md-6">
                        <div className="funkyradio-default">
                          <input
                            type="checkbox"
                            name="radio"
                            id={`checkbox${id + 1}`}
                            checked={selectedModules?.includes(res.id)}
                            onChange={() => handleModuleClick(res.id)}
                          />
                          <label for={`checkbox${id + 1}`}>
                            {res.categoryName}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  {noModuleErr && (
                    <div className="w-100 module-err text-end">
                      <p>Please select atleast one department</p>
                    </div>
                  )}
                  <div className="choose-continue-btn choose-continue-btn-margin">
                    <div className="next-button-wraper">
                      <button
                        onClick={
                          props?.user?.login_type === "google"
                            ? handleGoogleSubmit
                            : handleSubmit
                        }
                        disabled={selectedModules?.length === 0 || loadbtn}
                      >
                        {loadbtn ? (
                          <Spinner animation="border" />
                        ) : (
                          "Get Started"
                        )}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ModuleMain;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LogoIcon from "../../../assets/logo-white.png";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { register, registerThirdParty } from "../../../actions/userActions";
import API from "../../../services/ClientApi";
import Spinner from "react-bootstrap/Spinner";
import toast, { Toaster } from "react-hot-toast";
import { requestForToken } from "../../../firebase-config";
import { useGetPermissionsUrlsQuery } from "../../../slices/user/userSlice";

const ChooseCompanyMain = (props) => {
  const navigate = useNavigate();
  const [iload, setILoad] = useState(false);
  const [industry, setIndustry] = useState([]);
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [indErr, setIndErr] = useState(false);
  const [customInd, setCustomInd] = useState("");
  const [index, setIndex] = useState(0);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [nameInd, setNameInd] = useState("");
  const [pageContent, setPageContent] = useState({});
  const { onBoardingContent } = useSelector(
    (state) => state.authOnBoardingScreenContent
  );

  const {
    data: permissions,
    isLoading,
    isError,
    isSuccess,
  } = useGetPermissionsUrlsQuery(
    {},
    { skip: !localStorage.getItem("access_token") }
  );

  useEffect(() => {
    if (permissions && !isError && isSuccess && !isLoading) {
      localStorage.setItem("permissions", JSON.stringify(permissions?.data));
      // navigate(`/${localStorage.getItem("company")}/dashboard`);
      // navigate(`/${localStorage.getItem("company")}/invite-team`);
      // navigate(`/${localStorage.getItem("company")}/working-schedule`);
      if (props.user?.enable_payment) {
        navigate(`/${localStorage.getItem("company")}/payment`);
      } else {
        navigate(`/${localStorage.getItem("company")}/working-schedule`);
      }
      props.setUser({
        full_name: "",
        email_address: "",
        password: "",
        passwordConfirm: "",
        company_name: "",
        industry_id: 0,
        package_id: "",
        modules_category_ids: [],
        login_type: "",
        login_token: "",
        enable_payment: false,
      });
    }
    if (isError) {
      toast.error("Failed to fetch permissions");
    }
  }, [
    permissions,
    isError,
    isSuccess,
    localStorage.getItem("company"),
    isLoading,
  ]);

  useEffect(() => {
    if (onBoardingContent) {
      // eslint-disable-next-line array-callback-return
      onBoardingContent?.map((item, index) => {
        if (item?.url === "choose-industry") {
          setPageContent(item);
        }
      });
    }
  }, [onBoardingContent]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const handleIndustryChange = (industry, name) => {
    setIndErr(false);
    setSelectedIndustry(industry);
    setNameInd(name);
    if (name === "Others") {
      setCustomInd("");
    }
  };

  const handleSubmit = async () => {
    if (nameInd === "Others" && !customInd) {
      setIndErr(true);
    } else {
      try {
        setILoad(true);
        const responseModule = await API.get("auth/categories");

        const taskManagementModuleIds = []; // Initialize an empty array
        if (responseModule?.status === 200) {
          responseModule?.data?.data?.moduleCategories?.forEach((module) => {
            if (module.categoryName === "Task Management") {
              taskManagementModuleIds.push(module.id);
            }
          });
          if (customInd) {
            await API.post("auth/create-other-industry", {
              industry_name: customInd,
            });
          }
          const { login_type, login_token, ...userWithoutSensitiveInfo } =
            props.user;

          if (login_type === "google") {
            const response = await dispatch(
              registerThirdParty({
                login_type,
                login_token,
                industry_id: selectedIndustry,
                ...userWithoutSensitiveInfo,
                modules_category_ids: taskManagementModuleIds,
              })
            );

            if (response?.response?.data?.status === "error") {
              toast.error(response?.response?.data?.message);
            }
            if (response?.status === "success") {
              localStorage.setItem("access_token", response?.access_token);

              localStorage.setItem("company", response?.data?.companySlug);
              props?.setCompanySlug(response?.data?.companySlug);
            }
          } else {
            const response = await dispatch(
              register({
                ...userWithoutSensitiveInfo,
                modules_category_ids: taskManagementModuleIds,
                industry_id: selectedIndustry,
              })
            );

            if (response?.response?.data?.status === "error") {
              toast.error(response?.response?.data?.message);
            }
            if (response?.status === "success") {
              localStorage.setItem("access_token", response?.access_token);

              localStorage.setItem("company", response?.data?.companySlug);
              props?.setCompanySlug(response?.data?.companySlug);
            }
          }

          // For future use
          // props.setUser({
          //   ...props.user,
          //   industry_id: selectedIndustry,
          // });
          // sessionStorage.setItem(
          //   "user",
          //   JSON.stringify({
          //     ...props.user,
          //     industry_id: selectedIndustry,
          //   })
          // );
          // navigate("/modules");
        }
      } catch (error) {
      } finally {
        setILoad(false);
      }
    }
  };

  useEffect(() => {
    setSelectedIndustry(props?.user?.industry_id);
    if (
      props?.user?.login_type !== "google" &&
      (!props.user.full_name ||
        !props.user.password ||
        !props.user.passwordConfirm ||
        !props.user.company_name)
    ) {
      navigate("/create-account");
    }
    if (
      props?.user?.login_type === "google" &&
      (!props.user.full_name || !props.user.company_name)
    ) {
      navigate("/create-account");
    }
    const getIndustry = async () => {
      setLoad(true);
      try {
        const response = await API.get("auth/industries");
        setIndustry(response.data.data.industries);
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    };

    getIndustry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setIndErr(false);
    setCustomInd(e.target.value);
  };

  return (
    <section className="signup-main-wraper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 choose-industry-columns bg-column">
            <div className="signup-left-section">
              <div className="logo-top">
                {" "}
                <a href="#">
                  {" "}
                  <img src={LogoIcon} alt="logo" />{" "}
                </a>{" "}
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center sec-left-img-container">
                <div className="image-slider-main-wrap">
                  <div className="image-slider-wrap">
                    {" "}
                    {pageContent?.imageUrl && (
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          pageContent?.imageUrl
                        }
                        alt="hero-icon"
                      />
                    )}{" "}
                  </div>
                  <div className="signup-slider-wrap">
                    <Carousel
                      activeIndex={index}
                      controls={false}
                      onSelect={handleSelect}
                      indicators={false}
                    >
                      <Carousel.Item>
                        <div className="slider-signup-text-wrap">
                          <h2>{pageContent?.short_description}</h2>
                          <p>{pageContent?.long_description}</p>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 choose-industry-columns img-column">
            <div className="choose-industry-wraper">
              {load ? (
                <div className="w-100 d-flex justify-content-center global-spinner">
                  <Spinner animation="border" />
                </div>
              ) : (
                <div className="funkyradio funkyCompany">
                  <h3> {pageContent?.field_short_description} </h3>
                  <p> {pageContent?.field_long_description}</p>
                  <div className="row">
                    {industry.map((res, id) => (
                      <div className="col-md-6">
                        <div
                          className={`funkyradio-default ${
                            selectedIndustry === res.id &&
                            res.industryName === "Others"
                              ? "others-div"
                              : ""
                          } ${
                            selectedIndustry === res.id &&
                            res.industryName === "Others" &&
                            indErr
                              ? "err-other"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="radio"
                            id={`radio${id + 1}`}
                            checked={selectedIndustry === res.id}
                            onChange={() =>
                              handleIndustryChange(res.id, res.industryName)
                            }
                          />
                          {selectedIndustry === res.id &&
                            res.industryName === "Others" && (
                              <input
                                name="customInd"
                                className="others-input"
                                placeholder="Enter industry name"
                                value={customInd}
                                onChange={handleChange}
                              />
                            )}
                          <label for={`radio${id + 1}`}>
                            {res.industryName}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  {indErr && (
                    <div className="w-100 module-err text-end">
                      <p>Please enter industry name</p>
                    </div>
                  )}
                  <div className="choose-continue-btn choose-continue-btn-margin">
                    <div className="next-button-wraper">
                      <button
                        onClick={handleSubmit}
                        disabled={!selectedIndustry || iload}
                      >
                        {iload ? <Spinner size="1rem" /> : "Continue"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseCompanyMain;

import React from 'react'
import ForgotPasswordMain from './components/ForgotPasswordMain'
import "../styles/auth.scss";

const ForgotPassword = () => {
  return (
    <ForgotPasswordMain/>
  )
}

export default ForgotPassword
import React from "react";
import ChooseAccountMain from "./component/ChooseAccountMain";
import ChooseAccountMainGoogle from "./component/ChooseAccountMainGoogle";
import "../styles/auth.scss";

const ChooseAccount = (props) => {
  const isGoogle = JSON.parse(sessionStorage.getItem("user"));
  return isGoogle?.login_type === "google" ? (
    <ChooseAccountMainGoogle user={props.user} setUser={props.setUser} />
  ) : (
    <ChooseAccountMain user={props.user} setUser={props.setUser} />
  );
};

export default ChooseAccount;

import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import InviteTeamMember from "./pages/InviteTeam/InviteTeamMember.jsx";
import ChooseDepartmentMain from "./pages/ChooseDepartment/ChooseDepartmentMain.jsx";
import PackagesMain from "./pages/Packages/PackagesMain.jsx";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";

import ProtectedRouteAuth from "./components/protectedRouteAuth.jsx";
import Module from "./pages/Module/Module.jsx";
import EmailVerify from "./pages/EmailVerify/EmailVerify.jsx";
import VerifyInviteEmail from "./pages/VerifyInviteEmail/VerifyInviteEmail.jsx";
import Payment from "./pages/Payment/Payment.jsx";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword.jsx";
import ChangePassword from "./pages/ChangePassword/ChangePassword.jsx";
// import Subscriptions from "./pages/Subscriptions/Subscriptions";
import PageNotFound from "./pages/PageNotFound/PageNotFound.jsx";
import Signup from "./pages/Signup/Signup.jsx";
import Login from "./pages/Login/Login.jsx";
import ChooseCompany from "./pages/ChooseIndustry/ChooseCompany.jsx";
import ChooseAccount from "./pages/ChooseAccount/ChooseAccount.jsx";
import InviteTeam from "./pages/InviteTeam/InviteTeam.jsx";
// import NotificationPermissionPopup from "./components/NotificationPermissionPopup.jsx";
import TimeZoneAuthMain from "./pages/TimeZoneAuth/TimeZoneAuthMain.jsx";

function App() {
  const noop = () => {};

  // // // Disable specific console methods
  [
    "log",
    "warn",
    "error",
    "info",
    "debug",
    "table",
    "group",
    "groupEnd",
    "time",
    "timeEnd",
    "trace",
    "assert",
    "clear",
    "count",
    "countReset",
    "dir",
    "dirxml",
    "profile",
    "profileEnd",
  ].forEach((method) => {
    console[method] = noop;
  });

  const [companySlug, setCompanySlug] = useState("");
  const [collapse, setCollapse] = useState(false);
  const [user, setUser] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {
          full_name: "",
          email_address: "",
          password: "",
          passwordConfirm: "",
          company_name: "",
          industry_id: 0,
          // package_id: "",
          modules_category_ids: [],
          login_token: "",
          login_type: "",
          enable_payment: false,
          // seatCount: 3,
          // interval: "",
        }
  );

  // const token = Cookies.get("access_token");
  // const permissions = Cookies.get("permissions");
  // const company = Cookies.get("company");
  // const enableSwitches = Cookies.get("enableSwitch");

  // // Ensure enableSwitches is explicitly true
  // if (enableSwitches) {
  //   if (token) localStorage.setItem("access_token", token);
  //   if (permissions) localStorage.setItem("permissions", permissions);
  //   if (company) localStorage.setItem("company", company);

  //   // Now remove cookies after setting localStorage
  //   Cookies.remove("access_token");
  //   Cookies.remove("permissions");
  //   Cookies.remove("company");
  //   Cookies.remove("enableSwitch");
  // }

  const [isDark, setIsDark] = useState(
    // localStorage.getItem("isDark")
    //   ? localStorage.getItem("isDark").replace(/["']/g, "") === "default"
    //     ? "default"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color1"
    //     ? "color1"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color2"
    //     ? "color2"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color3"
    //     ? "color3"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color4"
    //     ? "color4"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color5"
    //     ? "color5"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color6"
    //     ? "color6"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color7"
    //     ? "color7"
    //     : "default"
    //   : "default"
    "default"
  );
  useEffect(() => {
    const handleStorageChange = async () => {
      // setIsDark(
      // localStorage.getItem("isDark")
      //   ? localStorage.getItem("isDark").replace(/["']/g, "") === "default"
      //     ? "default"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color1"
      //     ? "color1"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color2"
      //     ? "color2"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color3"
      //     ? "color3"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color4"
      //     ? "color4"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color5"
      //     ? "color5"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color6"
      //     ? "color6"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color7"
      //     ? "color7"
      //     : "default"
      //   : "default"
      // );
      setIsDark("default");
      // Add your logic to handle the change here
    };

    // Add storage event listener
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {}, [localStorage.getItem("company")]);

  // Initialize tinymce
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/tinymce/tinymce.min.js"; // Make sure this path is correct
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("company") &&
      !localStorage.getItem("isRegistering")
    ) {
      const authUrl = `${
        process.env.REACT_APP_MANAGE_URL
      }/${localStorage.getItem("company")}/dashboard`;

      //     // Try opening in the same tab first
      const newWindow = window.open(authUrl, "_self");

      // If pop-up blocked, fall back to same-page redirect
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === "undefined"
      ) {
        window.location.href = authUrl;
      }
    }
  }, [localStorage.getItem("access_token"), localStorage.getItem("company")]);

  return (
    <BrowserRouter>
      <Toaster />
      {/* <NotificationPermissionPopup isDark={isDark} /> */}

      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={
                <Login
                  setUser={setUser}
                  setCompanySlug={setCompanySlug}
                  companySlug={companySlug}
                />
              }
            />
          }
        />
        <Route
          path="/login"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={
                <Login
                  setUser={setUser}
                  setCompanySlug={setCompanySlug}
                  companySlug={companySlug}
                />
              }
            />
          }
        />
        <Route
          path="/forgot-password"
          element={
            <ProtectedRouteAuth isDark={isDark} children={<ForgotPassword />} />
          }
        />
        <Route
          path="/change-password"
          element={
            <ProtectedRouteAuth isDark={isDark} children={<ChangePassword />} />
          }
        />
        <Route
          path="/register"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={
                <Signup
                  user={user}
                  setUser={setUser}
                  setCompanySlug={setCompanySlug}
                  companySlug={companySlug}
                />
              }
            />
          }
        />
        <Route
          path="/choose-industry"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={
                <ChooseCompany
                  user={user}
                  setUser={setUser}
                  setCompanySlug={setCompanySlug}
                  companySlug={companySlug}
                />
              }
            />
          }
        />
        <Route
          path="/create-account"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={<ChooseAccount user={user} setUser={setUser} />}
            />
          }
        />
        <Route
          // path={`/payment`}
          path={`/${companySlug || localStorage.getItem("company")}/payment`}
          element={<Payment isDark={isDark} />}
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/working-schedule`}
          element={
            <div data-theme={"default"}>
              <TimeZoneAuthMain
                setUser={setUser}
                setCompanySlug={setCompanySlug}
                companySlug={companySlug}
              />
            </div>
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/invite-team`}
          element={
            <div data-theme={"default"}>
              <InviteTeam user={user} setUser={setUser} />
            </div>
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/invite-team-member`}
          element={
            <div data-theme={"default"}>
              <InviteTeamMember user={user} setUser={setUser} />
            </div>
          }
        />
        {/* <Route
          path="/packages"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={<PackagesMain user={user} setUser={setUser} />}
            />
          }
        /> */}
        <Route
          path="/modules"
          element={
            <ProtectedRouteAuth
              isDark={isDark}
              children={
                <Module
                  user={user}
                  setUser={setUser}
                  setCompanySlug={setCompanySlug}
                  companySlug={companySlug}
                />
              }
            />
          }
        />
        <Route path="/choose-department" element={<ChooseDepartmentMain />} />
        <Route path="/verify-email" element={<EmailVerify isDark={isDark} />} />
        <Route
          path="/accept-invitation"
          element={
            <VerifyInviteEmail
              isDark={isDark}
              setCompanySlug={setCompanySlug}
              companySlug={companySlug}
            />
          }
        />
        <Route path={`*`} element={<PageNotFound isDark={isDark} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { useFormik } from "formik";
import * as Yup from "yup";
import GoogleIcon from "../../../assets/google.svg";
import LogoIcon from "../../../assets/logo-white.png";
import Carousel from "react-bootstrap/Carousel";
import toast, { Toaster } from "react-hot-toast";
import { loginUser, loginUserThirdParty } from "../../../actions/userActions";
import { Spinner } from "react-bootstrap";
import API from "../../../services/ClientApi";
import axios from "axios";
import ChooseCompanyModal from "./ChooseCompanyModal";
import { requestForToken } from "../../../firebase-config";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useGetPermissionsUrlsQuery } from "../../../slices/user/userSlice";
import saveLocalStorageToCookies from "../../../helper/saveLocalStorageToCookies";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])/, // Alphanumeric validation
      "Password must be alphanumeric"
    )
    .min(6, "Password must be at least 6 characters"),
});

const LoginMain = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load] = useState(false);
  const [compload, setCompload] = useState(false);
  const [userCompanies, setUserCompanies] = useState([]);
  const [error, setError] = useState("");
  const [index, setIndex] = useState(0);
  const [auth, setAuth] = useState({
    email: "",
    login_token: "",
    login_type: "",
  });
  const [selectedCompany, setSelectedCompany] = useState("");
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [rload, setRload] = useState(false);
  const [gload, setGLoad] = useState(false);
  const [pageContent, setPageContent] = useState({});
  const { onBoardingContent } = useSelector(
    (state) => state.authOnBoardingScreenContent
  );
  const [showPassword, setShowPassword] = useState(false);

  const {
    data: permissions,
    isLoading,
    isError,
    isSuccess,
    refetch
  } = useGetPermissionsUrlsQuery(
    {},
    { skip: !localStorage.getItem("access_token") }
  );

  const togglePasswordVisibility = (e) => {
    e.stopPropagation();
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    if (permissions && !isError && isSuccess && !isLoading) {
      localStorage.setItem("permissions", JSON.stringify(permissions?.data));
      saveLocalStorageToCookies("/dashboard");
    }
    if (isError) {
      toast.error("Failed to fetch permissions");
    }
  }, [
    permissions,
    isError,
    isSuccess,
    localStorage.getItem("company"),
    isLoading,
  ]);

  useEffect(() => {
    if (onBoardingContent) {
      // eslint-disable-next-line array-callback-return
      onBoardingContent?.map((item, index) => {
        if (item?.url === "login") {
          setPageContent(item);
        }
      });
    }
  }, [onBoardingContent]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const [authType, setAuthType] = useState("password");

  const handleCloseCompanyModal = () => {
    setAuthType("password");
    setShowCompanyModal(false);
    setAuth({ email: "", login_token: "", login_type: "" });
    setUserCompanies([]);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setRload(true);
        const response = await dispatch(
          loginUser(values.email, values.password, selectedCompany)
        );
        await refetch();
        console.log("company before saving to localhost", response)
        if (response.status === "success") {
          localStorage.setItem("access_token", response?.access_token);
          
          localStorage.setItem("company", response?.data?.companySlug);
          props?.setCompanySlug(response?.data?.companySlug);
          if (
            permissions?.data?.status === "success" &&
            localStorage.getItem("company") &&
            localStorage.getItem("permissions")
          ) {
            // navigate(`/${localStorage.getItem("company")}/dashboard`);
            // toast.success("Logged in successfully", {
            //   position: toast.POSITION.TOP_CENTER,
            // });
            requestForToken();
            saveLocalStorageToCookies("/dashboard");
          }
        } else {
          setError(response.response.data.message);
        } // Other logic after successful login
      } catch (error) {
        console.log(error);
      } finally {
        setRload(false);
      }
    },
  });

  // Google auth
  const login = useGoogleLogin({
    scope: "openid profile email",
    onSuccess: (codeResponse) => {
      setAuthType("google");
      setGLoad(true);
      if (codeResponse) {
        axios
          .get(`https://www.googleapis.com/oauth2/v3/userinfo`, {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          })
          .then(async (res) => {
            if (res?.data?.email) {
              const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              setAuth({
                ...auth,
                email: res?.data?.email,
                login_token: res?.data?.sub,
                login_type: "google",
              });
              try {
                setCompload(true);
                const config = {
                  headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${
                      localStorage.getItem("access_token") || ""
                    }`,
                  },
                };
                const resp = await axios.post(
                  `${process.env.REACT_APP_BACKEND_API}auth/user-companies-3rdParty`,
                  {
                    email: res?.data?.email,
                    login_token: res?.data?.sub,
                  },
                  config
                );

                if (resp?.data?.message) {
                  props.setUser({
                    ...props.user,
                    email_address: res?.data?.email,
                    login_token: res?.data?.sub,
                    login_type: "google",
                    timeZone: timeZone, // Add timezone
                  });
                  sessionStorage.setItem(
                    "user",
                    JSON.stringify({
                      ...props.user,
                      email_address: res?.data?.email,
                      login_token: res?.data?.sub,
                      login_type: "google",
                      timeZone: timeZone, // Add timezone
                    })
                  );
                  navigate("/packages");
                } else {
                  const arrayOfCompanies = resp?.data?.data?.map(
                    (value, index) => ({
                      ...value,
                      key: index, // Include the index as a key for each object
                    })
                  );
                  if (resp?.data?.data) {
                    setUserCompanies(arrayOfCompanies);
                    if (arrayOfCompanies?.length > 0) {
                      setSelectedCompany(arrayOfCompanies[0]?.companyId);
                    }
                    if (arrayOfCompanies?.length === 1) {
                      setSelectedCompany(arrayOfCompanies[0]?.companyId);
                      try {
                        setRload(true);
                        const response = await dispatch(
                          loginUserThirdParty(
                            res?.data?.email,
                            "google",
                            res?.data?.sub,
                            selectedCompany
                          )
                        );

                        if (response.status === "success") {
                          localStorage.setItem(
                            "company",
                            response?.data?.companySlug
                          );
                          localStorage.setItem(
                            "access_token",
                            response?.access_token
                          );
                          await refetch();
                          props?.setCompanySlug(response?.data?.companySlug);
                          if (
                            permissions?.data?.status === "success" &&
                            localStorage.getItem("company") &&
                            localStorage.getItem("permissions")
                          ) {
                            // navigate(
                            //   `/${response?.data?.companySlug}/dashboard`
                            // );
                            // toast.success("Logged in successfully", {
                            //   position: toast.POSITION.TOP_CENTER,
                            // });
                            requestForToken();
                            saveLocalStorageToCookies("/dashboard");
                          }
                        } else {
                          setError(response.response.data.message);
                        } // Other logic
                      } catch (error) {
                      } finally {
                        setRload(false);
                      }
                    }
                    if (arrayOfCompanies?.length > 1) {
                      setShowCompanyModal(true);
                    }
                  }
                }
              } catch (error) {
              } finally {
                setCompload(false);
                setGLoad(false);
              }
            }
          })
          .catch((err) => {
            return err;
          });
      }
    },
    onError: (error) => console.log(error),
  });

  const handleSubmitGoogle = async () => {
    try {
      setRload(true);
      const response = await dispatch(
        loginUserThirdParty(
          auth.email,
          auth.login_type,
          auth.login_token,
          selectedCompany
        )
      );
      if (response.status === "success") {
        localStorage.setItem("access_token", response?.access_token);

        props?.setCompanySlug(response?.data?.companySlug);
        localStorage.setItem("company", response?.data?.companySlug);
      } else {
        setError(response.response.data.message);
      } // Other logic
    } catch (error) {
    } finally {
      setRload(false);
    }
  };

  const handleChange = (id) => {
    setSelectedCompany(id);
  };

  return (
    <section className="signup-main-wraper">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-6 choose-industry-columns bg-column">
            <div className="signup-left-section">
              <div className="logo-top logo-margin">
                {" "}
                <a href="#">
                  {" "}
                  <img src={LogoIcon} alt="logo" />{" "}
                </a>{" "}
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center sec-left-img-container">
                <div className="image-slider-main-wrap">
                  <div className="image-slider-wrap">
                    {" "}
                    {pageContent?.imageUrl && (
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          pageContent?.imageUrl
                        }
                        alt="hero-icon"
                      />
                    )}{" "}
                  </div>
                  <div className="signup-slider-wrap">
                    <Carousel
                      activeIndex={index}
                      controls={false}
                      onSelect={handleSelect}
                      indicators={false}
                    >
                      <Carousel.Item>
                        <div className="slider-signup-text-wrap">
                          <h2>{pageContent?.short_description}</h2>
                          <p>{pageContent?.long_description}</p>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 choose-industry-columns img-column">
            <div className="right-section-wrap h-100">
              <div className="sign-in-btn-text txt-desktop">
                {" "}
                <span> Don't have an account? </span>{" "}
                <a className="signup-btn" onClick={() => navigate("/register")}>
                  {" "}
                  Sign up{" "}
                </a>
              </div>
              <div className="form-section-wrap login-section-wrap">
                <h3> {pageContent?.field_short_description} </h3>
                <p> {pageContent?.field_long_description}</p>
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <label> Email Address* </label>
                    <input
                      type="email"
                      className={`form-control ${
                        (formik.touched.email && formik.errors.email) || error
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Info@youremail.com"
                      name="email"
                      onBlur={formik.handleBlur}
                      onChange={async (e) => {
                        formik.setErrors({
                          email: "",
                        });
                        setError("");
                        formik.handleChange(e);
                        if (
                          validationSchema.isValidSync({
                            email: e.target.value,
                            password: formik.values.password,
                          })
                        ) {
                          try {
                            setCompload(true);
                            const config = {
                              headers: {
                                "Content-type": "application/json",
                                Authorization: `Bearer ${
                                  localStorage.getItem("access_token") || ""
                                }`,
                              },
                            };
                            const resp = await axios.post(
                              `${process.env.REACT_APP_BACKEND_API}auth/user-companies-by-credential`,
                              {
                                email: e.target.value,
                                password: formik.values.password,
                              },
                              config
                            );
                            if (resp.data.data) {
                              setUserCompanies(resp?.data?.data);
                              if (resp?.data?.data?.length > 0) {
                                setSelectedCompany(
                                  resp?.data?.data[0]?.companyId
                                );
                              }
                              if (resp?.data?.data?.length > 1) {
                                setShowCompanyModal(true);
                              }
                            }
                          } catch (error) {
                          } finally {
                            setCompload(false);
                          }
                        }
                      }}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="invalid-feedback right-error">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>
                  <div className="form-group password-input-wrapper">
                    <label> Password* </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`form-control ${
                        (formik.touched.password && formik.errors.password) ||
                        error
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter Your Password"
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={async (e) => {
                        formik.setErrors({
                          password: "",
                        });
                        setError("");
                        formik.handleChange(e);
                        if (
                          validationSchema.isValidSync({
                            email: formik.values.email,
                            password: e.target.value,
                          })
                        ) {
                          try {
                            setCompload(true);
                            const config = {
                              headers: {
                                "Content-type": "application/json",
                                Authorization: `Bearer ${
                                  localStorage.getItem("access_token") || ""
                                }`,
                              },
                            };
                            const resp = await axios.post(
                              `${process.env.REACT_APP_BACKEND_API}auth/user-companies-by-credential`,
                              {
                                email: formik.values.email,
                                password: e.target.value,
                              },
                              config
                            );
                            if (resp.data.data) {
                              setUserCompanies(resp?.data?.data);
                              if (resp?.data?.data.length > 0) {
                                setSelectedCompany(
                                  resp?.data?.data[0]?.companyId
                                );
                              }
                              if (resp?.data?.data?.length > 1) {
                                setShowCompanyModal(true);
                              }
                            }
                          } catch (error) {
                          } finally {
                            setCompload(false);
                          }
                        }
                      }}
                      value={formik.values.password}
                    />
                    {formik.values.password && (
                      <span
                        className={`password-toggle-icon ${
                          (formik.touched.password && formik.errors.password) ||
                          error
                            ? "password-toggle-top"
                            : ""
                        }`}
                        onClick={togglePasswordVisibility}
                      >
                        <Icon
                          icon={showPassword ? "el:eye-close" : "el:eye-open"}
                        />
                      </span>
                    )}
                    {formik.touched.password && formik.errors.password && (
                      <div className="invalid-feedback right-error">
                        {formik.errors.password}
                      </div>
                    )}
                    {error && <p className="auth-err">{error}</p>}
                  </div>
                  <div className="control-group login-control-group ">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="control control--checkbox">
                          Remember Me
                          <input type="checkbox" />
                          <div className="control__indicator"></div>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <div className="btn-forgot-wrap">
                          {" "}
                          <a
                            onClick={() => navigate("/forgot-password")}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            Forgot your password?{" "}
                          </a>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-group-signup">
                    <button
                      className="d-flex align-items-center justify-content-center"
                      type="submit"
                      disabled={compload}
                    >
                      {" "}
                      {(load || rload || gload) && !showCompanyModal ? (
                        <Spinner animation="border" />
                      ) : (
                        "Login to Your Account"
                      )}{" "}
                    </button>
                  </div>
                  <div className="or-text-signup">
                    {" "}
                    <span> OR </span>{" "}
                  </div>
                </form>
                <div className="google-btn-wrap">
                  <button onClick={login}>
                    {" "}
                    <img src={GoogleIcon} alt="google" /> Continue with Google{" "}
                  </button>
                </div>
                <div className="sign-in-btn-text txt-mobile">
                  {" "}
                  <span> Don't have an account? </span>{" "}
                  <a
                    className="signup-btn"
                    onClick={() => navigate("/register")}
                  >
                    {" "}
                    Sign up{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChooseCompanyModal
        userCompanies={userCompanies}
        selectedCompany={selectedCompany}
        handleChange={handleChange}
        showCompanyModal={showCompanyModal}
        handleCloseCompanyModal={handleCloseCompanyModal}
        rload={rload}
        isDark={props.isDark}
        handleSubmit={
          authType !== "password" ? handleSubmitGoogle : formik.handleSubmit
        }
      />
    </section>
  );
};

export default LoginMain;

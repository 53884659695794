import React, { useEffect } from "react";
import "../styles/payment.css";
import Logo from "./Logo";
import { Container, Spinner } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentPage from "./PaymentPage";
import { useDispatch, useSelector } from "react-redux";
import { userProfilePage } from "../../../actions/userActions";
import { useNavigate } from "react-router-dom";
// Replace with your Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

// Custom appearance for Stripe Elements
const appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#3b5998",
    colorBackground: "#ffffff",
    colorText: "#333333",
    colorDanger: "#dc3545",
    fontFamily: "Inter, system-ui, sans-serif",
    spacingUnit: "4px",
    borderRadius: "6px",
  },
};

const PaymentMain = ({ isDark }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfile } = useSelector((state) => state?.profile);

  useEffect(() => {
    dispatch(userProfilePage());
  }, []);

  // If already paid, redirect to working hours screen
  useEffect(() => {
    if (userProfile && !userProfile?.packageDetails?.currentStatus) {
      navigate(`/${localStorage.getItem("company")}/working-schedule`);
    }
  }, [userProfile]);

  return (
    <div className="app_wrapper" data-theme={isDark}>
      <div className="w-100 d-flex payment-section-logo ps-4 pt-4">
        <Logo />
      </div>
      <Container className="d-flex flex-column align-items-center justify-content-center py-5 flex-grow-1">
        <Elements stripe={stripePromise} options={{ appearance }}>
          {!userProfile ? (
            <Spinner className="global-spinner" />
          ) : (
            <PaymentPage />
          )}
        </Elements>
      </Container>
    </div>
  );
};

export default PaymentMain;

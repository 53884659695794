import React from "react";
import SignupMain from "./components/SignupMain";
import "../styles/auth.scss";

const Signup = (props) => {
  return (
    <SignupMain
      user={props.user}
      setUser={props.setUser}
      setCompanySlug={props?.setCompanySlug}
      companySlug={props?.companySlug}
    />
  );
};

export default Signup;

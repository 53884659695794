import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authOnBoardingScreenContentAction } from "../actions/userActions";
import ScreenLoader from "./ScreenLoader";

const ProtectedRouteAuth = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [progress, setProgress] = useState(0);
  // const [load, setLoad] = useState(false);
  const { onBoardingContent } = useSelector(
    (state) => state.authOnBoardingScreenContent
  );

  // useEffect(() => {
  //   if (localStorage.getItem("access_token")) {
  //     navigate(`/${localStorage.getItem("company")}/dashboard`);
  //   }
  // }, [localStorage.getItem("access-token")]);

  useEffect(() => {
    const getAuthScreenContent = async () => {
      // setProgress(50);
      // setLoad(true);
      await dispatch(authOnBoardingScreenContentAction());
      // setProgress(100);
      // setLoad(false);
    };
    if (!onBoardingContent) {
      getAuthScreenContent();
    }
  }, []);

  return (
    // load ? (
    //   <ScreenLoader progress={progress}/>
    // ) : (
    <div data-theme="default">
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, { isDark: "default" })
      )}
    </div>
  );
};

export default ProtectedRouteAuth;

import React from "react";
import ChooseCompanyMain from "./components/ChooseCompanyMain";
import "../styles/auth.scss";

const ChooseCompany = (props) => {
  return (
    <ChooseCompanyMain
      user={props.user}
      setUser={props.setUser}
      setCompanySlug={props.setCompanySlug}
      companySlug={props.companySlug}
    />
  );
};

export default ChooseCompany;

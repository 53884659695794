import React from "react";
import ModuleMain from "./components/ModuleMain";

const Module = (props) => {
  return (
    <ModuleMain
      user={props.user}
      setUser={props.setUser}
      setCompanySlug={props.setCompanySlug}
      companySlug={props.companySlug}
    />
  );
};

export default Module;

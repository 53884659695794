import React from 'react'
import ChangePasswordMain from './components/ChangePasswordMain'
import "../styles/auth.scss";

const ChangePassword = () => {
  return (
    <ChangePasswordMain/>
  )
}

export default ChangePassword
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import HeroIcon from "../../../assets/schedule-hero.png";
import LogoIcon from "../../../assets/logo-white.png";
import Carousel from "react-bootstrap/Carousel";
import TimezoneAuthForm from "./TimezoneAuthForm";

const TimeZoneAuth = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <section className="signup-main-wraper dep-main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 choose-industry-columns bg-column">
            <div className="signup-left-section">
              <div className="logo-top">
                {" "}
                <a href="#">
                  {" "}
                  <img src={LogoIcon} alt="logo" />{" "}
                </a>{" "}
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center sec-left-img-container">
                <div className="image-slider-main-wrap">
                  <div className="image-slider-wrap">
                    {" "}
                    <img src={HeroIcon} alt="hero-icon" />{" "}
                  </div>
                  <div className="signup-slider-wrap">
                    <Carousel
                      activeIndex={index}
                      controls={false}
                      onSelect={handleSelect}
                      indicators={false}
                    >
                      <Carousel.Item>
                        <div className="slider-signup-text-wrap">
                          <h2>Your online business partners</h2>
                          <p>All of your operations on just a click away.</p>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 choose-industry-columns img-column">
            <div className="choose-industry-wraper justify-content-center">
              <div className="w-100 schedule-auth-wrapper">
                <h3> Company working schedule </h3>
                <p>for the staff/team</p>
                <div>
                  <TimezoneAuthForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TimeZoneAuth;

import React from "react";
import Cookies from "js-cookie";
function saveLocalStorageToCookies(link) {
  const cookieOptions = {
    path: "/",
  };
  console.log("company before saving to cookies", localStorage.getItem("company"))
  // Only set domain for production
  if (window.location.hostname !== "localhost") {
    cookieOptions.domain = process.env.REACT_APP_DOMAIN; // Works on real domains
    cookieOptions.secure = true; // Ensures security on HTTPS
    cookieOptions.sameSite = "None"; // Required for cross-domain cookies
  }
const company = localStorage.getItem("company");
  Cookies.set(
    "access_token",
    localStorage.getItem("access_token"),
    cookieOptions
  );
  Cookies.set(
    "permissions",
    localStorage.getItem("permissions"),
    cookieOptions
  );
  console.log("company before saving to cookies", localStorage.getItem("company"),company)
  Cookies.set("company", localStorage.getItem("company"), cookieOptions);
  Cookies.set("enableSwitch", true, cookieOptions);

  // Remove items from localStorage after setting cookies
  localStorage.removeItem("access_token");
  localStorage.removeItem("permissions");
  localStorage.removeItem("company");

  const manageDomain = process.env.REACT_APP_MANAGE_URL;

   window.location.href = `${manageDomain}/${company}${link}`;
}

export default saveLocalStorageToCookies;

/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LogoIcon from "../../../assets/logo-white.png";
import AddIcon from "../../../assets/add.svg";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import MultiSelect from "../../../components/MultiSelect";
import API from "../../../services/ClientApi";
import toast, { Toaster } from "react-hot-toast";
import NoSeatsModal from "./NoSeatsModal";
import { authOnBoardingScreenContentAction } from "../../../actions/userActions";
import saveLocalStorageToCookies from "../../../helper/saveLocalStorageToCookies";

const InviteTeamMain = (props) => {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [memberLoad, setMemberLoad] = useState(false);
  const navigate = useNavigate();
  const [memberOptions, setMemberOptions] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [memL, setMemL] = useState("");
  const [index, setIndex] = useState(0);
  const [members, setMembers] = useState([]);
  const [lastAddedIndex, setLastAddedIndex] = useState(members.length - 1);
  const [invalidIndices, setInvalidIndices] = useState([]);
  const [duplicateIndices, setDuplicateIndices] = useState([]);
  const [invalidDepartments, setInvalidDepartments] = useState([]);
  const [deptIndices, setDeptIndices] = useState([]);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [remainingInviteUsers, setRemainingInviteUsers] = useState(0);
  const [pageContent, setPageContent] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { onBoardingContent } = useSelector(
    (state) => state.authOnBoardingScreenContent
  );

  useEffect(() => {
    const getAuthScreenContent = async () => {
      // setProgress(50);
      // setLoad(true);
      await dispatch(authOnBoardingScreenContentAction());
      // setProgress(100);
      // setLoad(false);
    };
    if (!onBoardingContent) {
      getAuthScreenContent();
    }
  }, []);

  useEffect(() => {
    if (onBoardingContent) {
      // eslint-disable-next-line array-callback-return
      onBoardingContent?.map((item, index) => {
        if (item?.url === "invite-team") {
          setPageContent(item);
        }
      });
    }
  }, [onBoardingContent]);

  const handleEmailChange = (index, value) => {
    setInvalidIndices(invalidIndices.filter((item) => item !== index));
    setDuplicateIndices(duplicateIndices.filter((item) => item !== index));
    setMemL("");
    const newMembers = [...members];
    newMembers[index].email = value;
    setMembers(newMembers);
  };

  // Get role name by id
  const getRoleNameById = (id) => {
    const role = memberOptions.find((role) => role.id === id);
    return role ? role.type : null; // Return null if no matching role is found
  };

  const handleRoleChange = (index, value) => {
    const newMembers = [...members];
    newMembers[index].role = value;
    setMembers(newMembers);
    // if (getRoleNameById(value) !== "admin") {
    //   setDeptIndices((prevDeptIndices) => {
    //     // Check if the index is not already present in invalidIndices
    //     if (!prevDeptIndices.includes(index)) {
    //       return [...prevDeptIndices, index];
    //     }
    //     return prevDeptIndices; // No change if the index is already present
    //   });
    // } else if (getRoleNameById(value) === "admin") {
    //   setDeptIndices((prevDeptIndices) => {
    //     // Remove the index if it exists in the array
    //     const updatedDeptIndices = prevDeptIndices.filter(
    //       (deptIndex) => deptIndex !== index
    //     );
    //     return updatedDeptIndices;
    //   });
    // }
  };

  const handleAddMember = () => {
    if (remainingInviteUsers > 0) {
      setMembers([
        ...members,
        { email: "", role: memberOptions[0]?.id, department: [] },
      ]);
      setRemainingInviteUsers(remainingInviteUsers - 1);
      setLastAddedIndex(members.length);
    }
  };

  const handleRemoveMember = (index, e) => {
    const newMembers = members.filter((member, i) => i !== index);
    setMembers(newMembers);
    setRemainingInviteUsers(remainingInviteUsers + 1);
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleDepartmentSelect = (index, e) => {
    setInvalidDepartments(invalidDepartments.filter((item) => item !== index));
    const newMembers = [...members];
    newMembers[index].department = e;
    setMembers(newMembers);
  };

  const checkInvalid = () => {
    // Assume all emails are valid initially
    let allEmailsValid = true;

    members.forEach((member, index) => {
      if (member.email !== "") {
        if (!/\S+@\S+\.\S+/.test(member.email)) {
          // Use the functional form of the state updater to ensure the latest state
          setInvalidIndices((prevInvalidIndices) => {
            // Check if the index is not already present in invalidIndices
            if (!prevInvalidIndices.includes(index)) {
              return [...prevInvalidIndices, index];
            }
            return prevInvalidIndices; // No change if the index is already present
          });

          allEmailsValid = false;
        }
      }
    });
    return allEmailsValid;
  };

  const checkDepartment = () => {
    // Assume all emails are valid initially
    let allDepartmentValid = true;
    members.forEach((member, index) => {
      if (member.email !== "" && getRoleNameById(member.role) !== "admin") {
        if (member.department.length === 0) {
          // Use the functional form of the state updater to ensure the latest state
          setInvalidDepartments((prevInvalidDepartments) => {
            // Check if the index is not already present in invalidIndices
            if (!prevInvalidDepartments.includes(index)) {
              return [...prevInvalidDepartments, index];
            }
            return prevInvalidDepartments; // No change if the index is already present
          });

          allDepartmentValid = false;
        }
      }
    });

    // Return the result indicating whether all emails are valid
    return allDepartmentValid;
  };

  const checkDuplicate = async () => {
    let allDepartmentValid = true;
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token") || ""}`,
      },
    };

    // Use Promise.all to wait for all requests to complete
    await Promise.all(
      members.map(async (member, index) => {
        if (member.email !== "") {
          try {
            await axios.post(
              `${process.env.REACT_APP_BACKEND_API}company/email-duplication-validator`,
              { email: member.email },
              config
            );
          } catch (error) {
            allDepartmentValid = false;
            setDuplicateIndices((prevInvalidIndices) => {
              if (!prevInvalidIndices.includes(index)) {
                return [...prevInvalidIndices, index];
              }
              return prevInvalidIndices;
            });
          }
        }
      })
    );

    return allDepartmentValid;
  };

  const handleInvite = async () => {
    setLoad(true);
    const transformedArray = members.map((item) => ({
      ...item,
      department: item.department.map((dept) => dept.value),
    }));
    //Check if all members have an empty email value
    const isAllEmpty = transformedArray.every((member) => member.email === "");
    const checkEmail = checkInvalid();
    // const checkInvalidDepartment = checkDepartment();
    const checkDup = await checkDuplicate();
    // if (isAllEmpty || !checkEmail || !checkInvalidDepartment || !checkDup) {
    if (isAllEmpty || !checkEmail || !checkDup) {
      setLoad(false);
      if (isAllEmpty) {
        setMemL("Please add at least one team member");
        return; // Prevent further execution
      } else {
        return;
      }
    } else {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        const original = transformedArray.filter(
          (obj) => obj.email !== "" && obj.email !== null
        );
        const transformedArrayFinal = original.map((originalObject) => {
          const {
            department: modulesCategoriesIds,
            email: invitedEmailAddress,
            role: invitationRoleId,
            ...rest
          } = originalObject;
          return {
            modulesCategoriesIds,
            invitedEmailAddress,
            invitationRoleId,
            ...rest,
          };
        });
        // Find the Task Management module
        const taskManagementModule = departmentData.find(
          (module) => module.label === "Task Management"
        );

        const updatedMembers = transformedArrayFinal.map((member) => {
          const role = getRoleNameById(member.invitationRoleId); // Assuming you have this function

          if (role === "admin") {
            return { ...member, modulesCategoriesIds: [] };
          } else {
            return {
              ...member,
              modulesCategoriesIds: taskManagementModule
                ? [taskManagementModule.value]
                : [],
            };
          }
        });

        const data = await API.post(
          `${process.env.REACT_APP_BACKEND_API}company/user-invitation`,
          updatedMembers,
          config
        );
        if (data === undefined) {
          toast.error("Seat count is filled.");
        }
        if (data.data.status === "success") {
          toast.success("Invitations sent successfully");
          localStorage.removeItem("isRegistering");
          saveLocalStorageToCookies("/dashboard");
        }
      } catch (error) {
      } finally {
        setLoad(false);
      }
    }
  };

  const getMembers = async () => {
    setMemberLoad(true);
    try {
      const response = await API.get("company/admin-roles");
      const user = await API.get("users/basic-info");
      if (user?.data?.data?.canInvite === 1) {
        if (
          user?.data?.data?.roles?.some((role) => role.roleType === "admin")
        ) {
          const transformedArray = response?.data?.data?.companyRoles?.map(
            (item) => ({
              id: item.roleId,
              name: item.roleName,
              type: item.roleType,
            })
          );
          setMemberOptions(transformedArray);
          const updatedMembers = members.map((member, index) => ({
            ...member,
            role: transformedArray[0]?.id || "", // Assign the role from the response data, or an empty string if not available
            // You can update other properties as needed
          }));
          // Update the state with the modified members array
          setMembers(updatedMembers);
          const responseDept = await API.get("company/modules-categories");
          const transformedDeptArray =
            responseDept?.data?.data?.companyModules?.map((item) => ({
              label: item.categoryName,
              value: item.moduleCategoryId,
            }));
          setDepartmentData(transformedDeptArray);
        } else {
          navigate(`/${localStorage.getItem("company")}/invite-team-member`);
        }
      } else {
        navigate(`/${localStorage.getItem("company")}/dashboard`);
      }
      const registeredUsersResponse = await API.post(
        "company/registered-users",
        {
          searchTerm: "",
        }
      );
      if (registeredUsersResponse?.data?.status === "success") {
        const fetchedUsers = registeredUsersResponse?.data?.data || [];
        setRegisteredUsers(fetchedUsers);

        if (
          fetchedUsers.length === user?.data?.data?.packageDetails?.seatCount
        ) {
          navigate(`/${localStorage.getItem("company")}/dashboard`);
        }
        if (fetchedUsers.length < user?.data?.data?.packageDetails?.seatCount) {
          let remainingUsers =
            user?.data?.data?.packageDetails?.seatCount - fetchedUsers.length;

          let updatedLocalMembers = [];
          if (remainingUsers === 1) {
            updatedLocalMembers = [{ email: "", role: "", department: [] }];
            remainingUsers = remainingUsers - 1;
          } else if (remainingUsers === 2) {
            updatedLocalMembers = [
              { email: "", role: "", department: [] },
              { email: "", role: "", department: [] },
            ];
            remainingUsers = remainingUsers - 2;
          } else if (remainingUsers >= 3) {
            updatedLocalMembers = [
              { email: "", role: "", department: [] },
              { email: "", role: "", department: [] },
              { email: "", role: "", department: [] },
            ];
            remainingUsers = remainingUsers - 3;
          }
          const transformedArray = response?.data?.data?.companyRoles?.map(
            (item) => ({
              id: item.roleId,
              name: item.roleName,
              type: item.roleType,
            })
          );
          setMemberOptions(transformedArray);

          const updatedMembers = updatedLocalMembers.map((member, index) => ({
            ...member,
            role: transformedArray[0]?.id || "", // Assign the role from the response data, or an empty string if not available
            // You can update other properties as needed
          }));
          // Update the state with the modified members array
          setMembers(updatedMembers);
          setRemainingInviteUsers(remainingUsers);
        }
      }
      setMemberLoad(false);
    } catch (error) {
      setMemberLoad(false);
    }
  };

  useLayoutEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
    }

    getMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="signup-main-wraper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 choose-industry-columns bg-column">
            <div className="signup-left-section">
              <div className="logo-top">
                {" "}
                <a href="#">
                  {" "}
                  <img src={LogoIcon} alt="logo" />{" "}
                </a>{" "}
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center sec-left-img-container">
                <div className="image-slider-main-wrap">
                  <div className="image-slider-wrap">
                    {" "}
                    {pageContent?.imageUrl && (
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          pageContent?.imageUrl
                        }
                        alt="hero-icon"
                      />
                    )}{" "}
                  </div>
                  <div className="signup-slider-wrap">
                    <Carousel
                      activeIndex={index}
                      controls={false}
                      onSelect={handleSelect}
                      indicators={false}
                    >
                      <Carousel.Item>
                        <div className="slider-signup-text-wrap">
                          <h2>{pageContent?.short_description}</h2>
                          <p>{pageContent?.long_description}</p>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 choose-industry-columns img-column">
            <div className="invite-team-wraper">
              {memberLoad ? (
                <div className="w-100 d-flex justify-content-center global-spinner">
                  <Spinner animation="border" />
                </div>
              ) : (
                <>
                  <h3> {pageContent?.field_short_description} </h3>
                  <p> {pageContent?.field_long_description}</p>
                  {members.map((member, index) => (
                    <>
                      <div
                        key={index}
                        className={`form-group relative-position ${
                          deptIndices.includes(index) ? "no-dept-margin" : ""
                        }`}
                      >
                        <input
                          type="email"
                          className={`form-control ${memL ? "err-email" : ""} ${
                            invalidIndices.includes(index) ||
                            duplicateIndices.includes(index)
                              ? "err-email"
                              : ""
                          }`}
                          placeholder="Add email here"
                          value={member.email}
                          onChange={(e) =>
                            handleEmailChange(index, e.target.value)
                          }
                        />
                        <div
                          className={`select-control invite-select-control ${
                            memL ? "err-select-email" : ""
                          } ${
                            invalidIndices.includes(index) ||
                            duplicateIndices.includes(index)
                              ? "err-select-email"
                              : ""
                          }`}
                        >
                          <select
                            className="invite-role-select"
                            value={member.role}
                            onChange={(e) =>
                              handleRoleChange(index, e.target.value)
                            }
                          >
                            {memberOptions?.map((res, id) => (
                              <option key={id} value={res.id}>
                                {res.name.charAt(0).toUpperCase() +
                                  res.name.slice(1)}
                              </option>
                            ))}
                          </select>
                        </div>

                        {index === lastAddedIndex && memL && (
                          <div className="text-error">
                            Please enter atleast one email
                          </div>
                        )}
                        {invalidIndices.includes(index) && (
                          <div className="text-error">
                            Invalid email address
                          </div>
                        )}
                        {duplicateIndices.includes(index) && (
                          <div className="text-error">Email already exists</div>
                        )}
                        {index > 2 && (
                          <div
                            className="remove-module"
                            onClick={(e) => handleRemoveMember(index, e)}
                          >
                            -
                          </div>
                        )}
                      </div>
                      {/* {deptIndices.includes(index) && (
                        // <div className="form-group-dept">
                        //   <div className={`select-control select-control-dept`}>
                        <div className="multi-select-wrapper">
                          <MultiSelect
                            className={`${
                              invalidDepartments.includes(index)
                                ? "dept-err"
                                : ""
                            }`}
                            key="id"
                            options={departmentData}
                            onChange={(e) => handleDepartmentSelect(index, e)}
                            value={members[index].department}
                            isSelectAll={true}
                            menuPlacement={"bottom"}
                          />
                          {invalidDepartments.includes(index) && (
                            <div className="text-error-department">
                              Please select atleast one department
                            </div>
                          )}
                        </div>
                      )} */}
                    </>
                  ))}
                </>
              )}
              {!memberLoad && (
                <>
                  <div className="d-flex justify-content-between align-items-center buton-invite-wraper">
                    <button
                      onClick={() => {
                        if (remainingInviteUsers > 0) {
                          handleAddMember();
                        } else {
                          setShowModal(true);
                        }
                      }}
                    >
                      {" "}
                      <img src={AddIcon} alt="add" /> Add Another Member{" "}
                    </button>

                    <div className="back-button-wrap">
                      <button
                        onClick={() => {
                          // navigate(
                          //   `/${localStorage.getItem(
                          //     "company"
                          //   )}/dashboard`
                          // )
                          localStorage.removeItem("isRegistering");
                          saveLocalStorageToCookies("/dashboard");
                        }}
                      >
                        {" "}
                        Remind Me Later{" "}
                      </button>
                    </div>
                  </div>
                  <div className="choose-continue-btn">
                    <div className="row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div className="next-button-wraper invite-btn-margin">
                          <button
                            className="team-invite-button"
                            onClick={handleInvite}
                          >
                            {" "}
                            {load ? (
                              <Spinner animation="border" />
                            ) : (
                              "Invite Your Team"
                            )}{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <NoSeatsModal
          showAddModal={showModal}
          setShowAddModal={setShowModal}
          isDark="default"
        />
      )}
    </section>
  );
};

export default InviteTeamMain;

import { applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { thunk, withExtraArgument } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  authReducers,
  userProfileReducer,
  authOnBoardingScreenContentReducer,
} from "./reducers/userReducers.js";
import { textReducer } from "./reducers/textReducer.js";
import {
  companyModalDataReducer,
  userInviteeListingReducer,
  userListingReducer,
} from "./reducers/companyOverviewReducer.js";
import {
  billingReducer,
  paymentReducers,
  subscriptionReducer,
} from "./reducers/paymentReducers.jsx";
import { registeredUserCountReducer } from "./reducers/registeredUserCountReducer.js";
import { companiesListingReducer } from "./reducers/companiesReducer.js";
import { setupListeners } from "@reduxjs/toolkit/query";
import { PMBaseApi } from "./services/PMBaseApi.js";
import { retryMiddleware } from "./middlewares/retryMiddleware.js";
import { ADMBaseApi } from "./services/ADMBaseAPi.js";

const reducer = {
  auth: authReducers,
  profile: userProfileReducer,
  text: textReducer,
  userListing: userListingReducer,
  userTeamInviteeListing: userInviteeListingReducer,
  paymentHistory: paymentReducers,
  billing: billingReducer,
  subscription: subscriptionReducer,
  registeredUserCount: registeredUserCountReducer,
  authOnBoardingScreenContent: authOnBoardingScreenContentReducer,
  modalData: companyModalDataReducer,
  companyListData: companiesListingReducer,
};

let initialState = {};
const middleware = [withExtraArgument(thunk)];

const store = configureStore(
  {
    reducer: {
      ...reducer,
      [PMBaseApi.reducerPath]: PMBaseApi.reducer,
      [ADMBaseApi.reducerPath]: ADMBaseApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        PMBaseApi.middleware,
        ADMBaseApi.middleware,
        retryMiddleware
      ),
  },
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
setupListeners(store.dispatch);
export default store;

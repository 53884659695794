/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import LogoIcon from "../../../assets/logo-white.png";
import Carousel from "react-bootstrap/Carousel";
import { Spinner } from "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.css";
import { useSelector } from "react-redux";

const ChooseAccountMainGoogle = (props) => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [invalidCompany, setInvalidCompany] = useState(false);
  const [load] = useState(false);

  const [pageContent, setPageContent] = useState({});
  const { onBoardingContent } = useSelector(
    (state) => state.authOnBoardingScreenContent
  );

  useEffect(() => {
    if (onBoardingContent) {
      // eslint-disable-next-line array-callback-return
      onBoardingContent?.map((item, index) => {
        if (item?.url === "create-account") {
          setPageContent(item);
        }
      });
    }
  }, [onBoardingContent]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const formik = useFormik({
    initialValues: {
      fullName: props.user.full_name,
      company: props.user.company_name,
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .required("Required")
        .matches(/^[a-zA-Z\s]*$/, "Invalid name"),
      company: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      props.setUser({
        ...props.user,
        full_name: values.fullName,
        company_name: values.company,
      });
      sessionStorage.setItem(
        "user",
        JSON.stringify({
          ...props.user,
          full_name: values.fullName,
          company_name: values.company,
        })
      );
      navigate("/choose-industry");
    },
  });

  useEffect(() => {
    if (!props.user.email_address) {
      navigate("/register");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCompanyChange = (e) => {
    formik.handleChange(e); // This updates the formik state
    setInvalidCompany(false); // Reset the invalidCompany state to false
  };

  return (
    <section className="signup-main-wraper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 choose-industry-columns bg-column">
            <div className="signup-left-section">
              <div className="logo-top">
                {" "}
                <a href="">
                  {" "}
                  <img src={LogoIcon} alt="logo" />{" "}
                </a>{" "}
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center sec-left-img-container">
                <div className="image-slider-main-wrap">
                  <div className="image-slider-wrap">
                    {" "}
                    {pageContent?.imageUrl && (
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          pageContent?.imageUrl
                        }
                        alt="hero-icon"
                      />
                    )}{" "}
                  </div>
                  <div className="signup-slider-wrap">
                    <Carousel
                      activeIndex={index}
                      controls={false}
                      onSelect={handleSelect}
                      indicators={false}
                    >
                      <Carousel.Item>
                        <div className="slider-signup-text-wrap">
                          <h2>{pageContent?.short_description}</h2>
                          <p>{pageContent?.long_description}</p>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 choose-industry-columns img-column">
            <div className="create-account-main-wrap">
              <div className="create-account-form-wraper">
                <h3> {pageContent?.field_short_description} </h3>
                <p> {pageContent?.field_long_description}</p>
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <label> Full Name* </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.touched.fullName && formik.errors.fullName
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter Your Full Name"
                      {...formik.getFieldProps("fullName")}
                    />
                    {formik.touched.fullName && formik.errors.fullName && (
                      <div className="invalid-feedback">
                        {formik.errors.fullName}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label> Company* </label>
                    <input
                      type="text"
                      className={`form-control ${
                        (formik.touched.company && formik.errors.company) ||
                        invalidCompany
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter Your Company Name"
                      {...formik.getFieldProps("company")}
                      onChange={handleCompanyChange}
                    />
                    {formik.touched.company && formik.errors.company && (
                      <div className="invalid-feedback">
                        {formik.errors.company}
                      </div>
                    )}
                  </div>
                  <div className="button-continue-wrap">
                    <button
                      type="submit"
                      className="d-flex align-items-center justify-content-center acc-cont-btn"
                    >
                      {" "}
                      {load ? <Spinner animation="border" /> : "Continue"}{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseAccountMainGoogle;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import HeroIcon from "../../../assets/hero-image.png";
import LogoIcon from "../../../assets/logo-white.png";
import Carousel from "react-bootstrap/Carousel";
import "../styles/verify.css";
import { Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import PasswordStrengthBar from "react-password-strength-bar";
import "@fortawesome/fontawesome-free/css/all.css";
import API from "../../../services/ClientApi";
import { useSelector } from "react-redux";
import { requestForToken } from "../../../firebase-config";
import { getRetryModal } from "../../../components/RetryModal/RetryContext";
import saveLocalStorageToCookies from "../../../helper/saveLocalStorageToCookies";

const VerifyInviteEmailMain = (props) => {
  const navigate = useNavigate();
  const specificText = useSelector((state) => state.text);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get("token");
  const [load, setLoad] = useState(false);
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const initialValues = {
    fullName: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z\s]*$/, "Invalid name"),
    password: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])/, // Alphanumeric validation
        "Password must be alphanumeric"
      )
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    if (values.password && values.fullName) {
      const retryableRequest = async () => {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        setLoad(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API}auth/invited-user-register`,
          {
            token: token,
            full_name: values.fullName,
            password: values.password,
          },
          config
        );
        if (response.data.status === "success") {
          localStorage.setItem("isRegistering", true);
          localStorage.setItem("company", response?.data?.companySlug);
          props?.setCompanySlug(response?.data?.companySlug);
          localStorage.setItem("access_token", response.data.access_token);
          const permissions = await API.get("users/permissions-urls");
          localStorage.setItem(
            "permissions",
            JSON.stringify(permissions?.data?.data)
          );
          if (response.data.canInviteMore === 1) {
            if (
              response.data.userRoleName?.toString()?.toLowerCase() === "admin"
            ) {
              navigate(`/${localStorage.getItem("company")}/invite-team`);
            } else {
              navigate(
                `/${localStorage.getItem("company")}/invite-team-member`
              );
            }
          } else {
            // navigate(`/${localStorage.getItem("company")}/dashboard`);
            localStorage.removeItem("isRegistering");

            saveLocalStorageToCookies("/dashboard");
          }
          requestForToken();
          // toast.success("Invitation accepted successfully", {
          //   position: toast.POSITION.TOP_CENTER,
          //   autoClose: 3000,
          // });
        }
      };
      try {
        await retryableRequest();
      } catch (error) {
        // Show the retry modal with the retryable request as the callback
        toast.error(error.response.data.message);
        const showRetryModal = getRetryModal();
        showRetryModal(retryableRequest);
      } finally {
        setLoad(false);
      }
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);
  // const passwordsMatch =
  // formik.values.password &&
  // formik.values.password === formik.values.confirmPassword;
  return (
    <section className="signup-main-wraper" data-theme={props.isDark}>
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-6 choose-industry-columns bg-column">
            <div className="signup-left-section">
              <div className="logo-top">
                {" "}
                <a>
                  {" "}
                  <img src={LogoIcon} alt="logo" />{" "}
                </a>{" "}
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center sec-left-img-container">
                <div className="image-slider-main-wrap">
                  <div className="image-slider-wrap">
                    {" "}
                    <img src={HeroIcon} alt="hero-icon" />{" "}
                  </div>
                  <div className="signup-slider-wrap">
                    <Carousel
                      activeIndex={index}
                      controls={false}
                      onSelect={handleSelect}
                      indicators={false}
                    >
                      <Carousel.Item>
                        <div className="slider-signup-text-wrap">
                          <h2>Success to your business</h2>
                          <p>All of your operations on just a click away.</p>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 choose-industry-columns img-column">
            <div className="right-section-wrap h-100">
              <div className="form-section-wrap">
                <h3> Welcome to {specificText?.applicationName} </h3>
                <p> Get started - it's free. No credit card needed. </p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched }) => (
                    <Form>
                      <div className="form-group">
                        <label>Full Name</label>
                        <Field
                          type="text"
                          name="fullName"
                          className={`form-control ${
                            touched.fullName && errors.fullName
                              ? "is-invalid"
                              : ""
                          }`}
                          style={{ boxShadow: "none" }}
                          placeholder="Enter your full name"
                        />
                        <ErrorMessage
                          name="fullName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <Field
                          type="password"
                          name="password"
                          className={`form-control ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                          style={{ boxShadow: "none" }}
                          placeholder="Enter your password"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                        {values.password && (
                          <PasswordStrengthBar
                            className="pass-strength"
                            scoreWords={[
                              "Weak",
                              "Weak",
                              "Medium",
                              "Strong",
                              "Strong",
                            ]}
                            barColors={[
                              "#ddd",
                              "#ef4836",
                              "#f6b44d",
                              "#25c281",
                              "#25c281",
                            ]}
                            password={values.password}
                            shortScoreWord={false}
                            minLength={false}
                          />
                        )}
                      </div>
                      <div className="form-group form-confirm-pass-grp">
                        <label>Confirm Password</label>
                        <Field
                          type="password"
                          name="confirmPassword"
                          className={`form-control ${
                            touched.confirmPassword && errors.confirmPassword
                              ? "is-invalid"
                              : ""
                          }`}
                          style={{ boxShadow: "none" }}
                          placeholder="Enter your Confirm Password"
                        />
                        {values.password &&
                          values.password === values.confirmPassword && (
                            <i className="text-success fas fa-check check-confirm-pass"></i>
                          )}
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="btn-group-signup">
                        <button type="submit">
                          {" "}
                          {load ? (
                            <Spinner animation="border" />
                          ) : (
                            "Continue"
                          )}{" "}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyInviteEmailMain;

import React from "react";
import LoginMain from "./components/LoginMain";
import "../styles/auth.scss";

const Login = (props) => {
  return (
    <LoginMain
      isDark={props.isDark}
      setUser={props.setUser}
      setCompanySlug={props.setCompanySlug}
      companySlug={props.companySlug}
    />
  );
};

export default Login;
